import {
  Highlight,
  Input,
  Loader,
  Modal,
  Select as SM,
  Menu,
} from "@mantine/core";
import BackspaceIcon from "@mui/icons-material/Backspace";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import {
  Button,
  Checkbox,
  Fade,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  TextareaAutosize,
  Tooltip,
} from "@mui/material";
import TerminalIcon from "@mui/icons-material/Terminal";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SendIcon from "@mui/icons-material/Send";
import SendAndArchiveSharpIcon from "@mui/icons-material/SendAndArchiveSharp";
import {
  IconAlertTriangleFilled,
  IconDownload,
  IconLockAccess,
  IconPointFilled,
  IconRefresh,
  IconScan,
  IconReload,
  IconCopy,
} from "@tabler/icons-react";
import axios from "axios";
import FileDownload from "js-file-download";
import jwt_decode from "jwt-decode";
import moment from "moment/moment";
import printJS from "print-js";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { toast } from "react-toastify";
import {
  autoTest,
  breakPassword_2960_3560_3750,
  breakPassword_36_38,
  cancelProcess,
  changeCommand,
  checkToken,
  getAllGroup,
  getAllIOS,
  getAllLineOfStation,
  getAllLogOfStation,
  getAllStation,
  getAllValue,
  getListCisco,
  getListLastLog,
  installIOS,
  manualTest,
  powerControl,
  testDevices,
  updateIOS,
} from "../../api/DeviceAPI";
import ListHistory from "../DataGrid/ListHistory";
import { Timer } from "../Timer/Timer";
import "./TestDevice.css";

const TestDevice = ({ socket, user }) => {
  const [isloading, setIsLoading] = useReducer((x) => x + 1, 0);
  const [print, setPrint] = useState(false);
  const loadDiv = useRef(null);
  // const [scanCheck, setScanCheck] = useState(false);
  const [openHistory, setOpenHistory] = useState(false);
  const [openComfirm, setOpenComfirm] = useState(false);
  const [listLastLogs, setlistLastLogs] = useState([]);
  const [allStation, setAllStation] = useState([]);
  const [allLine, setAllLine] = useState([]);
  const [station, setStation] = useState(3);
  const [test, setTest] = useState(false);
  const [line, setLine] = useState();
  const [text, setText] = useState("");
  const [deleteRunLine, setDeleteRunLine] = useState("");
  const [selectStation, setSelectStation] = useState(
    user.full_name === "AU-dev" ? 1 : 2
  );
  const textareaRef = useRef(null);
  const [selectText, setSelectText] = useState("");
  const [valueConfirmPhysical, setValueConfirmPhysical] = useState({
    option: "",
    value: "",
    infoLine: {},
  });
  const [openArea, setOpenArea] = useState(true);
  const [listLine, setListLine] = useState([]);
  const [listRun, setListRun] = useState([]);
  const [listTesting, setListTesting] = useState([]);
  const [listOutput, setListOutput] = useState([]);
  const [listPreviousOutput, setListPreviousOutput] = useState([]);
  const [allGroupName, setAllGroupName] = useState([]);
  const [allGroupNameForUpdate, setAllGroupNameForUpdate] = useState([]);
  const [allGroupId, setAllGroupId] = useState([]);
  const [allGroupIdForUpdate, setAllGroupIdForUpdate] = useState([]);
  const [nameGroup, setNameGroup] = useState("");
  const [nameGroupBreak, setNameGroupBreak] = useState("");
  const [listIOS, setListIOS] = useState([]);
  const [idGroup, setIdGroup] = useState("");
  const [idModel, setIdModel] = useState("");
  const [iosName, setIOSName] = useState("");
  const [enableUpdateIOS, setEnableUpdateIOS] = useState(false);
  const [multipleRes, setMultipleRes] = useState([]);
  const [listTimer, setListTimer] = useState([]);
  const [enableBreak, setEnableBreak] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectIOS, setselectIOS] = useState([{ key: 0, value: "" }]);
  const [rows, setRows] = useState([]);
  const [listDeviceOn, setListDeviceOn] = useState([]);
  const [listDeviceOff, setListDeviceOff] = useState([]);
  const [listTestAuto, setListTestAuto] = useState([]);
  const [listNameStation, setListNameStation] = useState([]);
  const [nameStationSelect, setNameStationSelect] = useState(
    user.role === 0
      ? "AU-dev"
      : user.full_name === "AUS-only"
      ? "AUS-1"
      : user.full_name === "US-only"
      ? "US1 01-08"
      : user.full_name
  );
  const [listLastLogOfStation, setListLastLogOfStation] = useState([]);
  const [listLineOfStation, setListLineOfStation] = useState([]);
  const [listCisco, setListCisco] = useState([]);
  const [specialMoel, setSpecialMoel] = useState([]);
  const [RAMCheck, setRAMCheck] = useState([]);
  const [IOSList, setIOSList] = useState([]);
  const [highlightList, setHighlightList] = useState([]);
  const [logs, setLogs] = useState("");
  const [openedTerminal, setOpenedTerminal] = useState(false);
  const [infoStation, setInfoStation] = useState({
    line: "",
    port: "",
    clear: "",
    ip: "",
  });
  const [count, setCount] = useState(0);
  const [listStream, setListStream] = useState([]);
  // const user = jwt_decode(JSON.parse(localStorage.getItem("user"))?.token);

  // JSON.parse(localStorage.getItem("user"));
  const tokenCheck = async () => {
    try {
      const res = await axios.post(checkToken, {
        accessToken: user.accessToken,
      });
      console.log(res.data);
      if (res.data.pCode === 0) {
        localStorage.clear();
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllKeyValue = async () => {
    let temp = [];
    let temp1 = [];
    let temp2 = [];
    let temp3 = [];
    let temp4 = [];
    try {
      const res = await axios.post(getAllValue, {});
      res.data.pData.map((u) => {
        if (u.key === "MODEL_SPECIAL") {
          temp.push(u.value);
        }

        if (
          u.key === "HIGHLIGHT_STRING" ||
          u.key === "MODEL_SPECIAL" ||
          u.key === "MEMORY_SPECIAL"
        ) {
          temp1.push(u.value);
        }

        if (u.key === "CHECK_RAM_EXTRA") {
          temp2.push(u.value);
        }

        if (u.key.search("IOS_IMAGE") !== -1) {
          temp3.push(u);
        }

        if (u.key.search("TOPIC_STREAM") !== -1) {
          temp4.push(u.value);
        }
      });
      setSpecialMoel(temp);
      setHighlightList(temp1);
      setRAMCheck(temp2);
      setIOSList(temp3);
      setListStream(temp4);
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      field: "created_at",
      headerName: "Time",
      width: 110,
      renderCell: (params) => {
        return moment(parseInt(params.row.created_at)).format(
          "HH:mm:ss-D/M/YY"
        );
      },
    },
    { field: "line", headerName: "Line", width: 30 },
    { field: "PID", headerName: "PID", width: 170 },
    { field: "SN", headerName: "SN", width: 130 },
    {
      field: "log_status",
      headerName: "Status",
      width: 100,
      renderCell: (params) => {
        if (
          params.row.log_status.search("DPEL") !== -1 ||
          params.row.log_status === "Faulty"
        ) {
          return (
            <Tooltip
              title={<div style={{ width: "100%" }}>Click to show report</div>}
              placement="left"
            >
              <div
                style={{
                  color:
                    params.row.log_status.search("DPEL") !== -1
                      ? "green"
                      : "orange",
                  fontWeight: 700,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setOpenHistory(false);
                  setText(params.row.log_content);
                  setSelectText(params.row.log_content);
                  setLine(
                    allLine.filter((i) => i.id_line === params.row.log_line)[0]
                  );
                  setOpenArea(false);
                }}
              >
                {params.row.log_status.split("--")[0]}
              </div>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip
              title={
                <div style={{ width: "100%", wordWrap: "break-word" }}>
                  {params.row.log_content}
                </div>
              }
              placement="left"
            >
              <div style={{ color: "red", fontWeight: 700 }}>
                {params.row.log_status}
              </div>
            </Tooltip>
          );
        }
      },
    },
    { field: "command", headerName: "Command", width: 350 },
    {
      field: "@",
      headerName: "",
      width: 50,
      renderCell: (params) => {
        return (
          <Tooltip
            title={<div style={{ width: "100%" }}>Download file</div>}
            placement="left"
          >
            <IconButton
              onClick={() => {
                FileDownload(
                  params.row.log_content,
                  "line" +
                    params.row.line +
                    "_" +
                    moment(parseInt(params.row.created_at)).format(
                      "HH-mm-ss_DD-MM-YYYY"
                    ) +
                    "_" +
                    params.row.log_status
                );
              }}
            >
              <IconDownload width={18} height={18} />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  // Get all station
  const getAllSta = async () => {
    let name = [];
    try {
      const res = await axios.post(getAllStation);
      res.data.pData.map((u) => {
        name.push(u.sta_name);
      });
      setSelectStation(
        res.data.pData.filter((i) => i.sta_name === nameStationSelect)[0]
          ?.id_station
      );
      setListNameStation(
        name.sort(function (a, b) {
          if (a < b) {
            return -1;
          }
          if (a > b) {
            return 1;
          }
          return 0;
        })
      );
      setAllStation(
        res.data.pData.sort(function (a, b) {
          if (a.sta_name < b.sta_name) {
            return -1;
          }
          if (a.sta_name > b.sta_name) {
            return 1;
          }
          return 0;
        })
      );
      setInfoStation(
        res.data.pData.filter((i) => i.sta_name === nameStationSelect)[0]
      );

      getAllLastLog(
        res.data.pData.filter((i) => i.sta_name === nameStationSelect)[0]
          ?.id_station,
        res.data.pData
      );
    } catch (error) {
      console.log(error);
    }
  };

  //Get list IOS device
  const getAllIos = async () => {
    let temp = [];
    try {
      const res = await axios.post(getAllIOS);
      // console.log(res.data);
      temp = res.data;
      temp.unshift("None");
      setListIOS(temp);
    } catch (error) {
      console.log(error);
    }
  };

  //Get all group device
  const getAllGroups = async () => {
    let temp1 = [];
    let temp2 = [];
    let temp3 = [];
    let temp4 = [];
    try {
      const res = await axios.post(getAllGroup);
      for (let i = 0; i < res.data.pData.length; i++) {
        if (res.data.pData[i].group_action === "break") {
          temp1.push(res.data.pData[i].group_name);
          temp2.push(res.data.pData[i].id_group);
        }
        if (res.data.pData[i].group_action === "UpdateIOS") {
          temp3.push(res.data.pData[i].group_name);
          temp4.push(res.data.pData[i].id_group);
        }
      }
      temp1.unshift("None");
      temp3.unshift("None");
      setAllGroupNameForUpdate(temp3);
      setAllGroupName(temp1);
      setAllGroupId(temp2);
      setAllGroupIdForUpdate(temp4);
    } catch (error) {
      console.log(error);
    }
  };

  //Get list last log
  const getAllLastLog = async (selectStation, allStation) => {
    let temp = [];
    let temp1 = [];
    try {
      const res = await axios.post(getListLastLog, {
        id_station: selectStation,
      });
      for (let i = 0; i < res.data.listLastLog.length; i++) {
        if (Date.now() - res.data.listLastLog[i].created_at < 3600000) {
          temp.push(res.data.listLastLog[i]);
        }
      }
      setlistLastLogs(temp);

      for (let j = 0; j < allStation.length; j++) {
        const res1 = await axios.post(getListLastLog, {
          id_station: allStation[j].id_station,
        });
        let temp2 = [];
        for (let i = 0; i < res1.data.listLastLog.length; i++) {
          if (Date.now() - res1.data.listLastLog[i].created_at < 3600000) {
          temp2.push(res1.data.listLastLog[i]);
          }
        }
        temp1.push({ idSta: allStation[j].id_station, temp2 });
      }

      setListLastLogOfStation(temp1);
    } catch (error) {
      console.log(error);
    }
  };

  //Get history log
  const getHistory = async () => {
    try {
      const res = await axios.post(getAllLogOfStation, {
        id_station: selectStation,
      });
      setRows(res.data.listLog);
    } catch (error) {
      console.log(error);
    }
  };

  //Get list cisco
  const getListCISCO = async () => {
    try {
      let temp = [];
      const res = await axios.post(getListCisco);
      res.data.line?.map((u) => {
        temp.push(u?.split("|||")[1]);
      });
      setListCisco(temp);
    } catch (error) {
      console.log(error);
    }
  };

  //Get all line of station
  const getAllLineOfSta = async (id) => {
    let lineEnable = [];
    let temp1 = [];
    try {
      const res = await axios.post(getAllLineOfStation, { id: id });
      for (let i = 0; i < res.data.pData.length; i++) {
        if (res.data.pData[i].enable === 1) {
          lineEnable.push(res.data.pData[i]);
        }
      }

      for (let j = 0; j < allStation.length; j++) {
        const res1 = await axios.post(getAllLineOfStation, {
          id: allStation[j].id_station,
        });
        let temp2 = [];
        for (let i = 0; i < res1.data.pData.length; i++) {
          if (res1.data.pData[i].enable === 1) {
            temp2.push(res1.data.pData[i]);
          }
        }
        temp1.push({ id: allStation[j].id_station, lengthList: temp2.length });
      }
      setListLineOfStation(temp1);
      setAllLine(lineEnable);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmitTest = async () => {
    if (listLine.length > 0) {
      setListPreviousOutput(listOutput);
      setListOutput([]);
      let timerList = [];
      for (let t = 0; t < listLine.length; t++) {
        timerList.push({
          id_line: listLine[t].id_line,
          time: Date(Date.now()),
          action: "Test",
        });
      }
      setListTimer(timerList);
      socket?.emit("sendListTimer", timerList);
      socket?.emit("sendListRun", listRun);
      setMultipleRes([]);
      setListLine([]);
    } else {
      toast.error("No line is selected");
    }
    setLine();
    let temp = [];
    try {
      for (let i = 0; i < listLine.length; i++) {
        axios.post(testDevices, {
          infoStation: infoStation,
          listLine: listLine[i],
          action: "test",
          created_by: user.username,
        });
      }
      setListOutput(temp);
    } catch (error) {
      console.log(error);
    }
  };

  const handleBreakPassword = async () => {
    if (listLine.length > 0) {
      if (idModel !== "") {
        try {
          let timerList = [];
          for (let t = 0; t < listLine.length; t++) {
            timerList.push({
              id_line: listLine[t].id_line,
              time: Date(Date.now()),
              action: "Break",
            });
          }
          setListTimer(timerList);
          socket?.emit("sendListTimer", timerList);
          socket?.emit("sendListRun", listRun);
          setListLine([]);
          // setTextButton("breaking");
          // setTest(true);
          for (let i = 0; i < listLine.length; i++) {
            const res = await axios.post(
              nameGroupBreak.search("2960") !== -1
                ? breakPassword_2960_3560_3750
                : nameGroupBreak.search("3650") !== -1 && breakPassword_36_38,
              {
                action: "break",
                infoStation: infoStation,
                listLine: listLine[i],
                idGroup: idModel,
                created_by: user.username,
              }
            );
          }
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      toast.error("Please select line");
    }
  };

  const handleUpdateIOS = async () => {
    if (listLine.length > 0) {
      if (idGroup !== "") {
        try {
          let timerList = [];
          for (let t = 0; t < listLine.length; t++) {
            timerList.push({
              id_line: listLine[t].id_line,
              time: Date(Date.now()),
              action: "UpdateIOS",
            });
          }
          setListTimer(timerList);
          socket?.emit("sendListTimer", timerList);
          socket?.emit("sendListRun", listRun);
          setListLine([]);
          for (let i = 0; i < listLine.length; i++) {
            axios.post(updateIOS, {
              infoStation: infoStation,
              infoLine: listLine[i],
              ios_image: iosName,
              action: "updateios",
              idGroup: idGroup,
              nameGroup: nameGroup,
              created_by: user.username,
            });
          }
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      toast.error("Please select line");
    }
  };

  const handleCommand = async (id_line, command) => {
    try {
      const res = await axios.post(changeCommand, {
        id: listLastLogs.filter((i) => i.log_line === id_line)[0].id_log,
        command: command,
      });
      if (res.data.pCode === 1) {
        socket?.emit("changeCommand", {});
        // setIsLoading();
      } else {
        toast.error("Command fail");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const cancelAction = async (clear_line, port) => {
    try {
      await axios.post(cancelProcess, {
        line_port: port,
        clear_line: clear_line,
        sta_ip: infoStation.sta_ip,
      });
    } catch (error) {
      console.log(error);
    }
  };

  //Download file in history log
  const download = async () => {
    try {
      const nameFile =
        moment(Date.now()).format("HH_mm_ss-DD_MM_YYYY").toString() +
        "_TestFile.txt";
      FileDownload(text, nameFile);
    } catch (error) {
      console.log(error);
    }
  };

  const controlPower = async (action) => {
    try {
      if (listLine.length === allLine.length) {
        await axios.post(powerControl, {
          infoStation: infoStation,
          listLine: [],
          action: action,
          type: "all",
          length: allLine.length,
        });
        switch (action) {
          case "1":
            toast.success("Start to turn on");
            break;
          case "2":
            toast.success("Start to turn off");
            break;
          case "3":
            toast.success("Start to reboot");
            break;
        }

        setListLine([]);
      } else {
        for (let i = 0; i < listLine.length; i++) {
          await axios.post(powerControl, {
            infoStation: infoStation,
            listLine: listLine[i],
            action: action,
            type: "one",
            length: 1,
          });
        }
        switch (action) {
          case "1":
            toast.success("Start to turn on");
            break;
          case "2":
            toast.success("Start to turn off");
            break;
          case "3":
            toast.success("Start to reboot");
            break;
        }
        setListLine([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const copyMultipleLine = (key) => {
    let content = "";
    try {
      listLine.map((a) => {
        if (
          listTestAuto.filter((i) => i.id === a.id_line).length > 0 &&
          listTimer.filter((i) => i.id_line === a.id_line).length === 0
        ) {
          if (key === "line") {
            let VID =
              listLastLogs.filter((i) => i.log_line === a.id_line)[0] !==
                undefined &&
              listLastLogs
                .filter((i) => i.log_line === a.id_line)[0]?.log_content.search("VID:") !== -1
                ? listLastLogs
                    .filter((i) => i.log_line === a.id_line)[0]?.log_content?.split("VID:")[1]?.split("SN:")[0].replace(",","").trim()
                    // log_content.slice(
                    //   listLastLogs
                    //     .filter((i) => i.log_line === a.id_line)[0]
                    //     .log_content.search("VID:") + 4,
                    //   listLastLogs
                    //     .filter((i) => i.log_line === a.id_line)[0]
                    //     .log_content.search("VID:") + 8
                    // )
                : "";
            let PID =
              listLastLogs.filter((i) => i.log_line === a.id_line)[0] !==
              undefined
                ? listLastLogs.filter((i) => i.log_line === a.id_line)[0]
                    .log_status === "ROMMON:"
                  ? listLastLogs
                      .filter((i) => i.log_line === a.id_line)[0]
                      .log_content.split("\n")[1]
                      .split("|||")[1]
                  : listLastLogs.filter((i) => i.log_line === a.id_line)[0]
                      .log_status === "SWITCH:"
                  ? listLastLogs
                      .filter((i) => i.log_line === a.id_line)[0]
                      .log_content.split("\n")[1]
                      .split("|||")[1]
                  : listLastLogs.filter((i) => i.log_line === a.id_line)[0]
                      .log_status === "PASSWORD"
                  ? listLastLogs
                      .filter((i) => i.log_line === a.id_line)[0]
                      .log_content.split("\n")[1]
                      .split("--")[1]
                  : listLastLogs.filter((i) => i.log_line === a.id_line)[0].PID
                : "";
            let SN =
              listLastLogs.filter((i) => i.log_line === a.id_line)[0] !==
                undefined &&
              listLastLogs.filter((i) => i.log_line === a.id_line)[0].SN !== ""
                ? listLastLogs.filter((i) => i.log_line === a.id_line)[0]?.SN
                : "";

            let extraRAM =
              RAMCheck.length > 0 &&
              RAMCheck.filter(
                (i) =>
                  listLastLogs.filter((i) => i.log_line === a.id_line)[0] !==
                    undefined &&
                  listLastLogs
                    .filter((i) => i.log_line === a.id_line)[0]
                    .PID.search(i.split(":")[0]) !== -1
              )[0] !== undefined &&
              (parseInt(
                RAMCheck.filter(
                  (i) =>
                    listLastLogs.filter((i) => i.log_line === a.id_line)[0] !==
                      undefined &&
                    listLastLogs
                      .filter((i) => i.log_line === a.id_line)[0]
                      .PID.search(i.split(":")[0]) !== -1
                )[0].split(":")[1]
              ) *
                105) /
                100 <
                getRAMDevice(
                  listLastLogs.filter((i) => i.log_line === a.id_line)[0] !==
                    undefined &&
                    listLastLogs.filter((i) => i.log_line === a.id_line)[0]
                      .log_content !== ""
                    ? listLastLogs.filter((i) => i.log_line === a.id_line)[0]
                        .log_content
                    : ""
                )
                ? "          - extra RAM - " +
                  (
                    parseInt(
                      getRAMDevice(
                        listLastLogs.filter(
                          (i) => i.log_line === a.id_line
                        )[0] !== undefined &&
                          listLastLogs.filter(
                            (i) => i.log_line === a.id_line
                          )[0].log_content !== ""
                          ? listLastLogs.filter(
                              (i) => i.log_line === a.id_line
                            )[0].log_content
                          : ""
                      )
                    ) /
                    1024 /
                    1024
                  ).toFixed(2) +
                  "G (Default " +
                  (
                    parseInt(
                      RAMCheck.filter(
                        (i) =>
                          listLastLogs.filter(
                            (i) => i.log_line === a.id_line
                          )[0] !== undefined &&
                          listLastLogs
                            .filter((i) => i.log_line === a.id_line)[0]
                            .PID.search(i.split(":")[0]) !== -1
                      )[0].split(":")[1]
                    ) /
                    1024 /
                    1024
                  ).toFixed(2) +
                  "G)"
                : "";

            let specialItem =
              listLastLogs.filter((i) => i.log_line === a.id_line)[0] !==
                undefined &&
              listLastLogs.filter((i) => i.log_line === a.id_line)[0]
                .log_content !== ""
                ? specialMoel.filter(
                    (i) =>
                      getPIDItem(
                        listLastLogs.filter((i) => i.log_line === a.id_line)[0]
                          .log_content
                      )
                        .split("\n")
                        .slice(3)
                        .toString()
                        .search(i) !== -1
                  ).length > 0
                  ? "          - " +
                    specialMoel
                      .filter(
                        (i) =>
                          getPIDItem(
                            listLastLogs.filter(
                              (i) => i.log_line === a.id_line
                            )[0].log_content
                          )
                            .split("\n")
                            .slice(3)
                            .toString()
                            .search(i) !== -1
                      )
                      .toString()
                      .replace(/,/g, " , ") +
                    " inside"
                  : ""
                : "";
            if (
              (PID !== "" && PID !== undefined) ||
              (SN !== "" && SN !== undefined)
            ) {
              content =
                content +
                "Line " +
                a.line_number +
                ": " +
                PID.trim() +
                " " +
                VID +
                " SN: " +
                SN +
                extraRAM +
                specialItem +
                "\n";
            }

            navigator.clipboard.writeText(content);
            setListLine([]);
          }

          if (key === "PID") {
            let VID =
              listLastLogs.filter((i) => i.log_line === a.id_line)[0] !==
                undefined &&
              listLastLogs
                .filter((i) => i.log_line === a.id_line)[0]
                .log_content.search("VID:") !== -1
                ? listLastLogs
                    .filter((i) => i.log_line === a.id_line)[0]
                    .log_content.slice(
                      listLastLogs
                        .filter((i) => i.log_line === a.id_line)[0]
                        .log_content.search("VID:") + 4,
                      listLastLogs
                        .filter((i) => i.log_line === a.id_line)[0]
                        .log_content.search("VID:") + 8
                    )
                : "";
            let PID =
              listLastLogs.filter((i) => i.log_line === a.id_line)[0] !==
              undefined
                ? listLastLogs.filter((i) => i.log_line === a.id_line)[0]
                    .log_status === "ROMMON:"
                  ? listLastLogs
                      .filter((i) => i.log_line === a.id_line)[0]
                      .log_content.split("\n")[1]
                      .split("|||")[1]
                  : listLastLogs.filter((i) => i.log_line === a.id_line)[0]
                      .log_status === "SWITCH:"
                  ? listLastLogs
                      .filter((i) => i.log_line === a.id_line)[0]
                      .log_content.split("\n")[1]
                      .split("|||")[1]
                  : listLastLogs.filter((i) => i.log_line === a.id_line)[0]
                      .log_status === "PASSWORD"
                  ? listLastLogs
                      .filter((i) => i.log_line === a.id_line)[0]
                      .log_content.split("\n")[1]
                      .split("--")[1]
                  : listLastLogs.filter((i) => i.log_line === a.id_line)[0]
                      .log_status === "ERROR"
                  ? listCisco.filter(
                      (i) =>
                        listLastLogs
                          .filter((i) => i.log_line === a.id_line)[0]
                          .log_content?.search("=" + i) !== -1
                    )[0]
                  : listLastLogs.filter((i) => i.log_line === a.id_line)[0].PID
                : "";
            let SN =
              listLastLogs.filter((i) => i.log_line === a.id_line)[0] !==
                undefined &&
              listLastLogs.filter((i) => i.log_line === a.id_line)[0].SN !== ""
                ? listLastLogs.filter((i) => i.log_line === a.id_line)[0].SN
                : "";
            if (
              (PID !== "" && PID !== undefined) ||
              (SN !== "" && SN !== undefined)
            ) {
              content = content + PID.trim() + " " + VID + " SN: " + SN + "\n";
            }

            navigator.clipboard.writeText(content);
            setListLine([]);
          }

          if (key === "SN") {
            let SN =
              listLastLogs.filter((i) => i.log_line === a.id_line)[0] !==
                undefined &&
              listLastLogs.filter((i) => i.log_line === a.id_line)[0].SN !== ""
                ? listLastLogs.filter((i) => i.log_line === a.id_line)[0].SN
                : "";
            if (SN !== "") {
              content = content + SN + "\n";
            }
          }
        }
        navigator.clipboard.writeText(content);
        setListLine([]);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getPIDItem = (text) => {
    let listPID_SN = "";
    let arrayInfo = [];
    let arrayPID = [];
    let arraySN = [];
    let textTemp = text.replace(/\$  /g, "");
    let arrayLine = textTemp.split("\n");
    for (let i = 0; i < arrayLine.length; i++) {
      if (
        arrayLine[i].search("NAME:") !== -1 &&
        arrayLine[i].search("DESCR:") !== -1 &&
        arrayLine[i + 1].search("PID:") !== -1 &&
        arrayLine[i + 1].search("SN:") !== -1
      ) {
        if (
          arrayLine[i + 1]
            .slice(
              arrayLine[i + 1].search("PID:"),
              arrayLine[i + 1].search("PID:") + 100
            )
            .split(",")[0]
            .split(":")[1]
            .trim() !== "" &&
          arrayLine[i + 1]
            .slice(
              arrayLine[i + 1].search("SN:"),
              arrayLine[i + 1].search("SN:") + 100
            )
            .split(",")[0]
            .split(":")[1]
            .trim() !== ""
        ) {
          arrayInfo.push(arrayLine[i] + "\n" + arrayLine[i + 1]);
          arrayPID.push(
            arrayLine[i + 1]
              .slice(
                arrayLine[i + 1].search("PID:"),
                arrayLine[i + 1].search("PID:") + 100
              )
              .split(",")[0]
              .split(":")[1]
              .trim()
          );
          arraySN.push(
            arrayLine[i + 1]
              .slice(
                arrayLine[i + 1].search("SN:"),
                arrayLine[i + 1].search("SN:") + 100
              )
              .split(",")[0]
              .split(":")[1]
              .trim()
          );
        }
      }
    }

    for (let x = 0; x < arrayPID.length; x++) {
      for (let y = 0; y < arrayPID.length; y++) {
        if (
          arrayPID[x] === arrayPID[y] &&
          arraySN[x] === arraySN[y] &&
          x !== y
        ) {
          arrayInfo.splice(arrayPID.indexOf(arrayPID[y]), 1);
          arraySN.splice(arrayPID.indexOf(arrayPID[y]), 1);
          arrayPID.splice(arrayPID.indexOf(arrayPID[y]), 1);
        }
      }
    }

    for (let x = 0; x < arrayInfo.length; x++) {
      for (let y = 0; y < arrayInfo.length; y++) {
        if (
          arrayInfo[x]
            .slice(
              arrayInfo[x].search("DESCR:"),
              arrayInfo[x].search("DESCR:") + 100
            )
            .split("\n")[0]
            .split(":")[1]
            .trim() ===
            arrayInfo[y]
              .slice(
                arrayInfo[y].search("DESCR:"),
                arrayInfo[y].search("DESCR:") + 100
              )
              .split("\n")[0]
              .split(":")[1]
              .trim() &&
          x !== y
        ) {
          arrayInfo[x] = arrayInfo[x].replace(
            arrayInfo[x]
              .slice(
                arrayInfo[x].search("SN:"),
                arrayInfo[x].search("SN:") + 1000
              )
              .split("\n")[0]
              .trim(),
            arrayInfo[x]
              .slice(
                arrayInfo[x].search("SN:"),
                arrayInfo[x].search("SN:") + 1000
              )
              .split("\n")[0]
              .trim() +
              ", " +
              arrayInfo[y]
                .slice(
                  arrayInfo[y].search("SN:"),
                  arrayInfo[y].search("SN:") + 1000
                )
                .split("\n")[0]
                .split(":")[1]
                .trim()
          );

          arrayInfo.splice(y, 1);
        }
      }
    }

    arrayInfo.map((u) => {
      u = u.replace(
        u.slice(u.search("PID:"), u.search("PID:") + 2000).split("\n")[0],
        u
          .slice(u.search("PID:"), u.search("PID:") + 100)
          .split(",")[0]
          .split(":")[1]
          .trim() +
          "\n" +
          u
            .slice(u.search("SN:"), u.search("SN:") + 1000)
            .split("\n")[0]
            .split(":")[1]
            .trim() +
          "\n\n"
      );
      listPID_SN = listPID_SN + u + "\n";
    });
    // console.log(listPID_SN);
    return listPID_SN;
  };

  const getRAMDevice = (text) => {
    let textTemp = text.replace(/\$  /g, "");
    //Get memory in report
    let bytesOfMem = "";
    let bytesOfMemOver = textTemp.slice(
      textTemp.search("bytes of memory.") - 100,
      textTemp.search("bytes of memory.") + "bytes of memory.".length
    );
    if (bytesOfMemOver.search("bytes of memory.") !== -1) {
      bytesOfMem =
        bytesOfMemOver.split("\n")[bytesOfMemOver.split("\n").length - 1];
      let infoMem = bytesOfMem
        .slice(
          bytesOfMem.search("bytes of memory") - 100,
          bytesOfMem.search("bytes of memory")
        )
        .split(" ")
        .reverse()[1];

      return parseInt(infoMem.split("/")[0].replace("K", ""));
    } else {
      if (textTemp.search("physical memory") !== -1) {
        let memPhyOver = textTemp.slice(
          textTemp.search("physical memory") - 100,
          textTemp.search("physical memory") + "physical memory".length
        );
        let memPhysical =
          memPhyOver.split("\n")[memPhyOver.split("\n").length - 1];

        let infoMem = memPhysical
          .slice(
            memPhysical.search("bytes of physical memory") - 50,
            memPhysical.search("bytes of physical memory")
          )
          .split(" ")
          .reverse()[1];
        return parseInt(infoMem.split("/")[0].replace("K", ""));
      } else {
      }
    }
  };

  const getLog = (data) => {
    try {
      localStorage.setItem("log", localStorage.getItem("log") + data);
    } catch (error) {
      console.log(error);
    }
  };

  const getListIOS = (content) => {
    try {
      let temp = [];
      IOSList.map((u) => {
        if (content.search(u.value.split(":")[0]) !== -1) {
          temp.push(u.value.split(":")[1]);
        }
      });

      return temp;
    } catch (error) {
      console.log(error);
    }
  };

  const getShortText = (text) => {
    let textTemp = text.replace(/\$  /g, "");

    //Get inventory information in report
    let inventory = "";
    let invOver = textTemp
      .slice(textTemp.search("NAME: "), textTemp.search("NAME: ") + 3000)
      .split("\n");

    invOver.map((u) => {
      if (u.search("NAME: ") !== -1 || u.search("PID: ") !== -1) {
        inventory = inventory + u + "\n";
      }
    });

    //Get system image file information in report
    let imageFile = textTemp
      .substr(textTemp.search("System image file"), 500)
      .split("\n")[0];

    //Get device configuration in report
    let configKey = "";
    let configOver = "";
    if (textTemp.search("bytes of memory") !== -1) {
      configOver = textTemp
        .slice(
          textTemp.search("bytes of memory") - 200,
          textTemp.search("bytes of memory") + 100
        )
        .split("\n");
      configOver.map((u) => {
        if (u.search("bytes of memory") !== -1) {
          configKey = configKey + u;
        }
      });
    } else {
      if (textTemp.search("bytes of physical memory") !== -1) {
        configOver = textTemp
          .slice(
            textTemp.search(" bytes of physical memory") - 200,
            textTemp.search(" bytes of physical memory") + 100
          )
          .split("\n");
        configOver.map((u) => {
          if (u.search(" bytes of physical memory") !== -1) {
            configKey = configKey + u;
          }
        });
      }
    }

    let config = textTemp.slice(
      textTemp.indexOf(configKey),
      textTemp.search("Configuration register is") +
        textTemp
          .slice(
            textTemp.search("Configuration register is"),
            textTemp.search("Configuration register is") + 100
          )
          .split("\n")[0].length
    );

    return (
      (inventory + "\n\n" + imageFile).replace(/\n\n/g, "\n") +
      "\n\n" +
      config.replace(/\n\n/g, "\n")
    );
  };

  
  useEffect(() => {
    //Send request add new user
    // socket?.emit("newUser", user.username);
    // socket?.emit("newUser", user.username);
    // socket?.on("refuseNewUser", (data) => {
    //   toast.warning("Account is use. Please check and log in again!");
    //   setTimeout(() => {
    //     localStorage.clear();
    //     window.location.reload();
    //   }, 5000);
    // });
    //Listen action update list device running
    socket?.on("send-response", (data) => {
      setMultipleRes((multipleRes) => [...multipleRes, data]);
      setDeleteRunLine(data);
      // setlistLastLogs([]);

      setIsLoading();
      // getAllLastLog();
    });
    socket?.on("updateListRun", (data) => {
      setListTesting(data);
    });
    socket?.on("sendLogs", (data) => {
      let temp = String.fromCodePoint(8);
      const pattern = new RegExp(`${temp}        ${temp}`, "g");
      const pattern1 = new RegExp(`${temp}`, "g");
      setCount(0);
      setLogs(
        data.data
          .replace(pattern, "")
          .replace(pattern1, "")
          .replace(/ --More-- /g, "")
      );
      setTimeout(() => {
        autoScroll();
      }, 100);
    });
    socket?.on("updateListTimer", (data) => {
      setListTimer(data);
    });

    socket?.on("sendListDeviceOn", (data) => {
      // console.log(data)
      setListDeviceOn(data);
      // data.map((t) => {
      //   // if (listTestAuto.filter((item) => item.id === t.id).length === 0) {
      //   temp.push(t.infoLine);
      //   station_temp.push(t.station);
      //   listMode.push(t.mode);
      //   // setListTestAuto((listTestAuto) => [...listTestAuto, t])
      //   // }
      // });
      let temp = [];
      let station_temp = [];
      let listMode = [];
      data.map((t) => {
        // if (listTestAuto.filter((item) => item.id === t.id).length === 0) {
        temp.push(t.infoLine);
        station_temp.push(t.station);
        listMode.push(t.mode);
      });
      try {
        let timerList = [];
        for (let t = 0; t < temp.length; t++) {
          // if (listTestAuto.filter((item) => item.id === temp[t].id_line).length === 0) {
          timerList.push({
            id_line: temp[t].id_line,
            time: Date(Date.now()),
            action: "Auto-test",
            timeCheck: Date.now(),
          });
          // }
        }
        setListTimer(timerList);
        socket?.emit("sendListTimer", timerList);
        socket?.emit("sendListRun", temp);
        // setListLine([]);
        for (let i = 0; i < temp.length; i++) {
          axios.post(autoTest, {
            infoStation: station_temp[i],
            listLine: temp[i],
            mode: listMode[i],
            created_by: user.username,
          });
        }
        // }, 5000);
        // }
      } catch (error) {
        console.log(error);
      }
    });

    socket?.on("sendListTesting", (data) => {
      setListTestAuto(data);
      // console.log("testing", data)
    });
    socket?.on("sendListDeviceOff", (data) => {
      setListDeviceOff(data);
      // console.log("off", data)
    });

    socket?.on("updateCommand", (data) => {
      // if (data.type === "input") {
      setIsLoading();
      // }

      // if (data.type === "test") {
      //   getAllLastLog();
      // }

      // toast.success("update command")
    });
  }, [socket]);
  // console.log(listDeviceOff);
  //Update list line run
  useEffect(() => {
    let temp = [];
    setMultipleRes(
      multipleRes.filter((value, index, self) => {
        return self.indexOf(value) === index;
      })
    );
    multipleRes.map((u) => {
      temp.push(u.id);
    });

    setListRun(listRun.filter((item) => temp.includes(item.id_line) === false));
    setIsLoading();
  }, [deleteRunLine]);

  useEffect(() => {
    setTimeout(() => {
      let temp1 = listDeviceOn.filter((value, index, self) => {
        return self.indexOf(value) === index;
      });
    }, 5000);
  }, [listDeviceOn]);

  useEffect(() => {
    getAllSta();
    getAllLastLog(selectStation, allStation);
    getHistory();
    getAllGroups();
    getAllIos();
    setListLine([]);
    getListCISCO();
    getAllKeyValue();
    if (selectStation !== undefined) {
      getAllLineOfSta(selectStation);
    }
    // if (station !== undefined) {
    //   getAllLineOfSta(station);
    // }
    loadDiv.current?.scrollIntoView({ behavior: "smooth" });
  }, [isloading, selectStation]);

  useEffect(() => {
    // getAllSta();
    // getAllLastLog();
    // getHistory();
    // getAllGroups();
    // getAllIos();
    // setListLine([]);
    // getListCISCO();
    // if (selectStation !== undefined) {
    //   getAllLineOfSta(selectStation);
    // }
    // if (user !== undefined) {
    // tokenCheck();
    if (Date.now() / 1000 - user.exp > 0) {
      toast.warning("Login session expired. Please log in again!");
      setTimeout(() => {
        localStorage.clear();
        window.location.reload();
      }, 5000);
    }
    // }
    // setNameStationSelect()
    // setTimeout(() => {
    //   console.log(allStation.filter((i)=>i.sta_name.toLocaleLowerCase().search(user.full_name.toLocaleLowerCase())!==-1)[0]?.sta_name)
    // }, 1000);
  }, []);
  const autoScroll = () => {
    var textarea = document.getElementById("textarea_id");
    textarea.scrollTop = textarea.scrollHeight;
  };

  return (
    <div className="viewHome">
      <div>
        <div className="divStation" style={{ position: "relative" }}>
          {/* <FormControl sx={{ minWidth: 100 }}>
            <InputLabel id="demo-simple-select-autowidth-label">
              Station
            </InputLabel>
            <Select
              disabled={test}
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              value={selectStation}
              onChange={(e) => {
                setStation(e.target.value);
                setInfoStation(
                  allStation.filter(
                    (item) => item.id_station === e.target.value
                  )[0]
                );
              }}
              size="small"
              autoWidth
              label="Station"
            >
              {allStation.length > 0 &&
                allStation.map((u, index) => (
                  <MenuItem
                    key={index}
                    sx={{ m: 1, minWidth: 200 }}
                    value={index}
                    onClick={() => {
                      getAllLineOfSta(u.id_station);
                      setSelectStation(u.id_station)
                      console.log(u.id_station);
                      setListOutput([]);
                    }}
                  >
                    {u.sta_name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl> */}
          {/* <SM
            placeholder="Station"
            // label="Station"
            data={listNameStation}
            value={nameStationSelect}
            onChange={(e) => {
              setSelectStation(
                allStation.filter((i) => i.sta_name === e)[0].id_station
              );
              // setStation(e.target.value);
              setInfoStation(
                allStation.filter((item) => item.sta_name === e)[0]
              );

              setNameStationSelect(e);
            }}
            style={{ width: "5%" }}
            // searchable
          ></SM> */}

          <Button
            style={{
              width: "10%",
              display: user.role === 0 ? "block" : "none",
            }}
            variant={nameStationSelect === "AU-dev" ? "contained" : "outlined"}
            size="small"
            onClick={() => {
              setSelectStation(2);
              setInfoStation(
                allStation.filter((item) => item.sta_name === "AU-dev")[0]
              );
              setNameStationSelect("AU-dev");
            }}
          >
            AU-dev -{" "}
            <span style={{ color: "#25f125", fontWeight: "800" }}>
              {listLastLogOfStation.filter((i) => i.idSta === 2).length > 0 &&
                listLastLogOfStation
                  .filter((i) => i.idSta === 2)[0]
                  .temp2.filter((i) => i.log_status.search("DPEL") !== -1)
                  .length}
            </span>
            /
            <span style={{ color: "orange", fontWeight: "800" }}>
              {listLastLogOfStation.filter((i) => i.idSta === 2).length > 0 &&
                listLastLogOfStation
                  .filter((i) => i.idSta === 2)[0]
                  .temp2.filter((i) => i.log_status === "Faulty").length}
            </span>
            /
            <span style={{ color: "orangered", fontWeight: "800" }}>
              {listLastLogOfStation.filter((i) => i.idSta === 2).length > 0 &&
                listLineOfStation.filter((i) => i.id === 2)[0] !== undefined &&
                listLineOfStation.filter((i) => i.id === 2)[0].lengthList -
                  listLastLogOfStation
                    .filter((i) => i.idSta === 2)[0]
                    .temp2.filter(
                      (i) =>
                        i.log_status.search("DPEL") !== -1 ||
                        i.log_status === "Faulty"
                    ).length}
            </span>
          </Button>
          <Button
            style={{
              width: "10%",
              marginLeft: "10px",
              display:
                user.full_name.search("AUS-1") !== -1 ||
                user.role === 0 ||
                user.full_name === "AUS-only"
                  ? "block"
                  : "none",
            }}
            variant={nameStationSelect === "AUS-1" ? "contained" : "outlined"}
            size="small"
            onClick={() => {
              setSelectStation(5);
              setInfoStation(
                allStation.filter((item) => item.sta_name === "AUS-1")[0]
              );
              setNameStationSelect("AUS-1");
            }}
          >
            AUS-1 -{" "}
            <span style={{ color: "#25f125", fontWeight: "800" }}>
              {listLastLogOfStation.filter((i) => i.idSta === 5).length > 0 &&
                listLastLogOfStation
                  .filter((i) => i.idSta === 5)[0]
                  .temp2.filter((i) => i.log_status.search("DPEL") !== -1)
                  .length}
            </span>
            /
            <span style={{ color: "orange", fontWeight: "800" }}>
              {listLastLogOfStation.filter((i) => i.idSta === 5).length > 0 &&
                listLastLogOfStation
                  .filter((i) => i.idSta === 5)[0]
                  .temp2.filter((i) => i.log_status === "Faulty").length}
            </span>
            /
            <span style={{ color: "orangered", fontWeight: "800" }}>
              {listLastLogOfStation.filter((i) => i.idSta === 5).length > 0 &&
                listLineOfStation.filter((i) => i.id === 5)[0] !== undefined &&
                listLineOfStation.filter((i) => i.id === 5)[0].lengthList -
                  listLastLogOfStation
                    .filter((i) => i.idSta === 5)[0]
                    .temp2.filter(
                      (i) =>
                        i.log_status.search("DPEL") !== -1 ||
                        i.log_status === "Faulty"
                    ).length}
            </span>
          </Button>
          <Button
            style={{
              width: "10%",
              marginLeft: "10px",
              display:
                user.full_name.search("AUS-1") !== -1 ||
                user.role === 0 ||
                user.full_name === "AUS-only"
                  ? "block"
                  : "none",
            }}
            variant={nameStationSelect === "AUS-2" ? "contained" : "outlined"}
            size="small"
            onClick={() => {
              setSelectStation(3);
              setInfoStation(
                allStation.filter((item) => item.sta_name === "AUS-2")[0]
              );
              setNameStationSelect("AUS-2");
            }}
          >
            AUS-2 -{" "}
            <span style={{ color: "#25f125", fontWeight: "800" }}>
              {listLastLogOfStation.filter((i) => i.idSta === 3).length > 0 &&
                listLastLogOfStation
                  .filter((i) => i.idSta === 3)[0]
                  .temp2.filter((i) => i.log_status.search("DPEL") !== -1)
                  .length}
            </span>
            /
            <span style={{ color: "orange", fontWeight: "800" }}>
              {listLastLogOfStation.filter((i) => i.idSta === 3).length > 0 &&
                listLastLogOfStation
                  .filter((i) => i.idSta === 3)[0]
                  .temp2.filter((i) => i.log_status === "Faulty").length}
            </span>
            /
            <span style={{ color: "orangered", fontWeight: "800" }}>
              {listLastLogOfStation.filter((i) => i.idSta === 3).length > 0 &&
                listLineOfStation.filter((i) => i.id === 3)[0] !== undefined &&
                listLineOfStation.filter((i) => i.id === 3)[0].lengthList -
                  listLastLogOfStation
                    .filter((i) => i.idSta === 3)[0]
                    .temp2.filter(
                      (i) =>
                        i.log_status.search("DPEL") !== -1 ||
                        i.log_status === "Faulty"
                    ).length}
            </span>
          </Button>
          <Button
            style={{
              width: "10%",
              marginLeft: "10px",
              display:
                user.full_name.search("US1 ") !== -1 ||
                user.role === 0 ||
                user.full_name.search("AUS-1") !== -1 ||
                user.full_name === "US-only"
                  ? "block"
                  : "none",
            }}
            variant={
              nameStationSelect === "US1 01-08" ? "contained" : "outlined"
            }
            size="small"
            onClick={() => {
              setSelectStation(1);
              setInfoStation(
                allStation.filter((item) => item.sta_name === "US1 01-08")[0]
              );
              setNameStationSelect("US1 01-08");
            }}
          >
            US 01-08 -{" "}
            <span style={{ color: "#25f125", fontWeight: "800" }}>
              {listLastLogOfStation.filter((i) => i.idSta === 1).length > 0 &&
                listLastLogOfStation
                  .filter((i) => i.idSta === 1)[0]
                  .temp2.filter((i) => i.log_status.search("DPEL") !== -1)
                  .length}
            </span>
            /
            <span style={{ color: "orange", fontWeight: "800" }}>
              {listLastLogOfStation.filter((i) => i.idSta === 1).length > 0 &&
                listLastLogOfStation
                  .filter((i) => i.idSta === 1)[0]
                  .temp2.filter((i) => i.log_status === "Faulty").length}
            </span>
            /
            <span style={{ color: "orangered", fontWeight: "800" }}>
              {listLastLogOfStation.filter((i) => i.idSta === 1).length > 0 &&
                listLineOfStation.filter((i) => i.id === 1)[0] !== undefined &&
                listLineOfStation.filter((i) => i.id === 1)[0].lengthList -
                  listLastLogOfStation
                    .filter((i) => i.idSta === 1)[0]
                    .temp2.filter(
                      (i) =>
                        i.log_status.search("DPEL") !== -1 ||
                        i.log_status === "Faulty"
                    ).length}
            </span>
          </Button>
          <Button
            style={{
              width: "10%",
              marginLeft: "10px",
              display:
                user.full_name.search("US1 ") !== -1 ||
                user.role === 0 ||
                user.full_name.search("AUS-1") !== -1 ||
                user.full_name === "US-only"
                  ? "block"
                  : "none",
            }}
            variant={
              nameStationSelect === "US2 11-18" ? "contained" : "outlined"
            }
            size="small"
            onClick={() => {
              setSelectStation(4);
              setInfoStation(
                allStation.filter((item) => item.sta_name === "US2 11-18")[0]
              );
              setNameStationSelect("US2 11-18");
            }}
          >
            US 11-18 -{" "}
            <span style={{ color: "#25f125", fontWeight: "800" }}>
              {listLastLogOfStation.filter((i) => i.idSta === 4).length > 0 &&
                listLastLogOfStation
                  .filter((i) => i.idSta === 4)[0]
                  .temp2.filter((i) => i.log_status.search("DPEL") !== -1)
                  .length}
            </span>
            /
            <span style={{ color: "orange", fontWeight: "800" }}>
              {listLastLogOfStation.filter((i) => i.idSta === 4).length > 0 &&
                listLastLogOfStation
                  .filter((i) => i.idSta === 4)[0]
                  .temp2.filter((i) => i.log_status === "Faulty").length}
            </span>
            /
            <span style={{ color: "orangered", fontWeight: "800" }}>
              {listLastLogOfStation.filter((i) => i.idSta === 4).length > 0 &&
                listLineOfStation.filter((i) => i.id === 4)[0] !== undefined &&
                listLineOfStation.filter((i) => i.id === 4)[0].lengthList -
                  listLastLogOfStation
                    .filter((i) => i.idSta === 4)[0]
                    .temp2.filter(
                      (i) =>
                        i.log_status.search("DPEL") !== -1 ||
                        i.log_status === "Faulty"
                    ).length}
            </span>
          </Button>

          <div
            style={{
              width: "45%",
              textAlign: "right",
              position: "absolute",
              right: 15,
            }}
          >
            <Button
              sx={{
                // width: "10px",
                fontSize: "0.6rem",
                boxShadow: "0 0 5px 0 gray",
                fontWeight: 700,
              }}
              // size="small"
              onClick={() => {
                setOpenHistory(true);
                setOpenModal(true);
                setLine(undefined);
              }}
            >
              History / Edit
            </Button>
          </div>
          {/* <Button
            variant="contained"
            style={{ marginLeft: "1%" }}
            onClick={() => {
              Test();
            }}
          >
            manual Test
          </Button> */}

          {/* <Button
            variant="contained"
            style={{ marginLeft: "1%" }}
            onClick={async () => {
              test_Auto();
            }}>
            auto-test
          </Button> */}

          {/* <Button
            variant="contained"
            style={{ marginLeft: "1%" }}
            onClick={() => {
              setEnableUpdateIOS(false)
              setNameGroup("")
              setIOSName("")
              if (idModel === "") {
                setEnableBreak(!enableBreak);
              } else {
                handleBreakPassword();
              }
            }}
          >
            break
          </Button> */}

          {/* <Button
            variant="contained"
            style={{ marginLeft: "1%" }}
            onClick={() => {
              setEnableBreak(false)
              setIdModel("")
              setNameGroupBreak("")
              if (idGroup === "" || iosName === "") {
                setEnableUpdateIOS(!enableUpdateIOS)
              } else {
                handleUpdateIOS();
                console.log("update");
              }
            }}
          >
            UpdateIOS
          </Button> */}

          <SM
            style={{
              width: "10%",
              margin: "0vh 0 0 1%",
              display: enableBreak ? "flex" : "none",
            }}
            placeholder="Select group"
            data={allGroupName}
            value={nameGroupBreak}
            onChange={(e) => {
              if (e === "None") {
                setIdModel("");
                setNameGroupBreak("");
              } else {
                setNameGroupBreak(e);
                setIdModel(allGroupId[allGroupName.indexOf(e) - 1]);
              }
            }}
            searchable
            clearable={true}
          />
        </div>

        <div style={{ display: "flex" }}>
          <SM
            style={{
              width: "18%",
              margin: "1vh 0 0 3%",
              display: enableUpdateIOS ? "block" : "none",
            }}
            value={nameGroup}
            placeholder="Select group"
            data={allGroupNameForUpdate}
            onChange={(e) => {
              if (e === "None") {
                setIdGroup("");
              } else {
                setIdGroup(
                  allGroupIdForUpdate[allGroupNameForUpdate.indexOf(e) - 1]
                );
                setNameGroup(e);
              }
            }}
            searchable
          />

          <Tooltip
            title={
              <Button variant="contained" size="small">
                Update list IOS
              </Button>
            }
            placement="right"
          >
            <SM
              style={{
                width: "18%",
                margin: "1vh 0 0 30px",
                display: enableUpdateIOS ? "block" : "none",
                zIndex: 100,
              }}
              value={iosName}
              placeholder="Select IOS"
              data={listIOS}
              onChange={(e) => {
                if (e === "None") {
                  setIOSName("");
                } else {
                  setIOSName(listIOS[listIOS.indexOf(e)]);
                }
              }}
              searchable
            />
          </Tooltip>
        </div>
      </div>
      <div className="info">
        <div className="divInfoStationTest" style={{ width: "100%" }}>
          <div className="divInfoLine">
            <div className="infoLineTitleTest">
              <div
                style={{
                  textAlign: "center",
                  position: "absolute",
                  left: "0.3%",
                  // marginTop: "0.5%",
                  cursor: "pointer",
                }}
              >
                {listTestAuto.length > 0 ? (
                  listTestAuto.filter(
                    (i) => i.infoLine.station_id === selectStation
                  ).length === allLine.length ? (
                    <Tooltip title="Enable all" placement="top">
                      <IconLockAccess
                        width={20}
                        height={20}
                        onClick={() => {
                          socket?.emit("updateListTesting", {
                            status: "enable",
                            allLine: allLine,
                          });
                          // getAllLastLog();
                        }}
                        color="red"
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Disable all" placement="top">
                      <IconScan
                        width={20}
                        height={20}
                        onClick={() => {
                          socket?.emit("updateListTesting", {
                            status: "disable",
                            allLine: allLine,
                          });
                        }}
                        color="green"
                      />
                    </Tooltip>
                  )
                ) : (
                  <Tooltip title="Disable all" placement="top">
                    <IconScan
                      width={20}
                      height={20}
                      onClick={() => {
                        socket?.emit("updateListTesting", {
                          status: "disable",
                          allLine: allLine,
                        });
                      }}
                      color="green"
                    />
                  </Tooltip>
                )}
              </div>
              <div
                style={{
                  textAlign: "center",
                  position: "absolute",
                  left: "1.9%",
                  width: "8%",
                  // border:"solid 1px"
                }}
              >
                <Menu
                  position="top"
                  arrowPosition="center"
                  offset={12}
                  trigger="hover"
                >
                  <Menu.Target>
                    <Tooltip title="Power control">
                      <PowerSettingsNewIcon
                        style={{
                          width: 18,
                          height: 18,
                          margin: "0vh 0 -5px -3px",
                          cursor: "pointer",
                        }}
                      />
                    </Tooltip>
                  </Menu.Target>

                  <Menu.Dropdown
                    style={{
                      height: "2.5vh",
                      backgroundColor: "rgb(186 200 208 / 65%)",
                    }}
                  >
                    {/* <Menu.Item style={{maxHeight:"1px", margin:"0 0 2vh 0"}}> */}
                    <div
                      style={{
                        display: "flex",
                        width: "100px",
                        // margin: "2% 0 0 0",
                        height: "5vh",
                      }}
                    >
                      <Tooltip
                        title={
                          <span style={{ fontSize: "0.6rem" }}>Turn on</span>
                        }
                        placement="top"
                      >
                        <PowerSettingsNewIcon
                          style={{
                            width: 18,
                            height: 18,
                            color: "green",
                            margin: "0 22% 0 0",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            if (listLine.length === 0) {
                              toast.error("No line is selected");
                            } else {
                              controlPower("1");
                            }
                          }}
                        />
                      </Tooltip>

                      <Tooltip
                        title={
                          <span style={{ fontSize: "0.6rem" }}>Turn off</span>
                        }
                        placement="top"
                      >
                        <PowerSettingsNewIcon
                          style={{
                            width: 18,
                            height: 18,
                            color: "red",
                            margin: "0 22% 0 0",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            if (listLine.length === 0) {
                              toast.error("No line is selected");
                            } else {
                              controlPower("2");
                            }
                          }}
                        />
                      </Tooltip>

                      <Tooltip
                        title={
                          <span style={{ fontSize: "0.6rem" }}>Reboot</span>
                        }
                        placement="top"
                      >
                        <IconReload
                          style={{
                            width: 18,
                            height: 18,
                            color: "orange",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            if (listLine.length === 0) {
                              toast.error("No line is selected");
                            } else {
                              controlPower("3");
                            }
                          }}
                        />
                      </Tooltip>
                    </div>
                    {/* </Menu.Item> */}
                  </Menu.Dropdown>
                </Menu>
                <Checkbox
                  size="small"
                  style={{
                    // width: "15%",
                    margin: "0 2px 0 5%",
                    padding: 0,
                    color: "rgb(0, 105, 167)",
                  }}
                  checked={listLine.length === allLine.length ? true : false}
                  onChange={(e) => {
                    if (test === false) {
                      if (e.target.checked) {
                        setListLine(allLine);
                        setListRun(allLine);
                      } else {
                        setListLine([]);
                        setListRun([]);
                      }
                    }
                  }}
                ></Checkbox>
                Line{" "}
                <IconButton
                  style={{ width: 32, height: 25, marginTop: "-0.5vh" }}
                  onClick={() => {
                    copyMultipleLine("line");
                  }}
                >
                  <IconCopy width={40} height={40} color="rgb(0, 105, 167)" />
                </IconButton>
              </div>
              <div
                style={{
                  textAlign: "center",
                  position: "absolute",
                  width: "6%",
                  left: "10%",
                }}
              >
                Status
                {listLastLogs.length > 0 && (
                  <Tooltip title="Refresh status" placement="top">
                    <IconButton
                      variant="contained"
                      style={{ marginLeft: "1%" }}
                      onClick={() => {
                        setlistLastLogs([]);
                        setListOutput([]);
                        // setIsLoading()
                      }}
                      size="small"
                    >
                      <IconRefresh
                        width={15}
                        height={15}
                        color="rgb(0, 105, 167)"
                      />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
              <div style={{ width: "5%", position: "absolute", left: "18%" }}>
                PID
                <IconButton
                  style={{ width: 32, height: 25, marginTop: "-0.5vh" }}
                  onClick={() => {
                    copyMultipleLine("PID");
                  }}
                >
                  <IconCopy width={40} height={40} color="rgb(0, 105, 167)" />
                </IconButton>
              </div>
              <div style={{ width: "5%", position: "absolute", left: "27.5%" }}>
                VID
              </div>
              <div style={{ width: "5%", position: "absolute", left: "33%" }}>
                SN
                <IconButton
                  style={{ width: 32, height: 25, marginTop: "-0.5vh" }}
                  onClick={() => {
                    copyMultipleLine("SN");
                  }}
                >
                  <IconCopy width={40} height={40} color="rgb(0, 105, 167)" />
                </IconButton>
              </div>
              <div style={{ width: "5%", position: "absolute", left: "41%" }}>
                Created at
              </div>
              <div style={{ width: "5%", position: "absolute", left: "59%" }}>
                Action
              </div>
              <div style={{ width: "5%", position: "absolute", left: "79%" }}>
                Note{" "}
              </div>
              <div style={{ width: "5%", position: "absolute", left: "92%" }}>
                Other items{" "}
              </div>
              {/* <div>Scan</div> */}
            </div>
            {allLine.length > 0
              ? allLine.map((u, index) => (
                  <div
                    className="lineDiv"
                    style={{
                      backgroundColor:
                        parseInt(u.line_number) % 2 === 0
                          ? "#b9cfd769"
                          : "white",
                      color:
                        Date.now() -
                          listLastLogs.filter(
                            (i) => i.log_line === u.id_line
                          )[0]?.created_at >
                        3600000
                          ? "gray"
                          : "black",
                      opacity:
                        Date.now() -
                          listLastLogs.filter(
                            (i) => i.log_line === u.id_line
                          )[0]?.created_at >
                          3600000 &&
                        listLastLogs.filter((i) => i.log_line === u.id_line)[0]
                          ?.log_status !== "loadingIOS"
                          ? 0.5
                          : 1,
                      // border:Date.now() - listLastLogs.filter((i)=>i.log_line === u.id_line)[0]?.created_at > 3600000?"solid 1px gray":"none"
                    }}
                  >
                    {/* listLastLogs.filter((i)=>i.log_line === u.id_line)[0]?.created_at */}
                    {/* {console.log(Date.now() - new Date("14:19:04-14/7/23"))} */}
                    <div
                      style={{
                        position: "absolute",
                        left: "1",
                        marginTop: "4px",
                        cursor: "pointer",
                        // padding: "0 0 0 10px",
                        // height:"100%",
                        zIndex: 1,
                        width: "2%",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        height: "3.5vh",
                      }}
                    >
                      {listTestAuto.filter((i) => i.id === u.id_line).length >
                        0 &&
                      listTestAuto.filter((i) => i.id === u.id_line)[0]
                        .status === "edit" ? (
                        <Tooltip title="Enable scan" placement="left">
                          <IconLockAccess
                            width={20}
                            height={20}
                            onClick={() => {
                              socket?.emit("updateListTesting", u);
                              getAllLastLog(selectStation, allStation);
                            }}
                            color="red"
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Disable scan" placement="left">
                          <IconScan
                            width={20}
                            height={20}
                            onClick={() => {
                              socket?.emit("updateListTesting", u);
                            }}
                            color="green"
                          />
                        </Tooltip>
                      )}
                    </div>

                    {/* <Tooltip
                      title={"Line " + u.line_number}
                      placement="bottom-end"
                    > */}
                    <div className="infoLineTest" key={index}>
                      {/* LINE NUMBER */}
                      <div
                        className="lineName"
                        style={{
                          width: "9%",
                          fontSize: "0.85rem",
                          display: "flex",
                          position: "absolute",
                          left: "1",
                          height: "100%",
                          alignItems: "center",
                          justifyContent: "right",
                          // border:"solid 1px"
                        }}
                      >
                        {listTestAuto.filter((t) => t.id === u.id_line).length >
                        0 ? (
                          listTestAuto.filter((t) => t.id === u.id_line)[0]
                            .status === "edit" ? (
                            <div style={{ width: "30%", textAlign: "center" }}>
                              <IconPointFilled
                                width={35}
                                height={25}
                                style={{ color: "orange" }}
                              />
                            </div>
                          ) : (
                            <div style={{ width: "30%", textAlign: "center" }}>
                              <IconPointFilled
                                width={35}
                                height={25}
                                style={{ color: "green" }}
                              />
                            </div>
                          )
                        ) : (
                          <div style={{ width: "30%", textAlign: "center" }}>
                            <Loader
                              variant="bars"
                              size="xs"
                              color="orange"
                              style={{ margin: "0 0px 0 1%" }}
                            />
                          </div>
                        )}
                        <div style={{ width: "55%" }}>
                          <Checkbox
                            size="small"
                            style={{ width: "20px", padding: 0 }}
                            checked={listLine.includes(u) ? true : false}
                            onChange={(e) => {
                              if (
                                listTesting.filter(
                                  (i) => i.id_line === u.id_line
                                ).length === 0
                              ) {
                                if (e.target.checked) {
                                  setListLine((listLine) => [...listLine, u]);
                                  setListRun((listLine) => [...listLine, u]);
                                } else {
                                  setListLine(
                                    listLine.filter((item) => item !== u)
                                  );
                                  setListRun(
                                    listLine.filter((item) => item !== u)
                                  );
                                }
                              }
                            }}
                          ></Checkbox>
                          {/* <Tooltip
                            title={
                              <Tooltip title="Open CLI" placement="top">
                                <div
                                  style={{
                                    textAlign: "center",
                                    // marginLeft:-10,
                                  }}
                                > */}
                          {/*  */}
                          {/* </div>
                              </Tooltip> */}
                          {/* }
                            placement="left"
                          > */}
                          <span
                            style={{
                              textDecoration:
                                listDeviceOff.filter((i) => i.id === u.id_line)
                                  .length > 0 &&
                                listTestAuto.filter(
                                  (i) => i.id === u.id_line
                                )[0] === undefined
                                  ? "line-through red 2px"
                                  : "none",
                            }}
                          >
                            Line {u.line_number}:
                          </span>
                          {/* </Tooltip> */}
                          {/* {
                            //Alert model special
                            multipleRes.length > 0 ? (
                              // "lon hon 0"
                              multipleRes.filter((i) => i.id === u.id_line)
                                .length > 0 ? (
                                // "co id trung"
                                multipleRes.filter(
                                  (i) => i.result[0].id_line === u.id_line
                                )[0].result[0].RAMcheck !== undefined &&
                                multipleRes.filter(
                                  (i) => i.result[0].id_line === u.id_line
                                )[0].result[0].RAMcheck !== "" ? (
                                  // <Tooltip title="Warning">
                                  //   <IconButton
                                  //     style={{
                                  //       padding: 2,
                                  //       margin: "0 0 0 5px",
                                  //       zIndex: 1000,
                                  //     }}
                                  //     onClick={() => {
                                  //       setText(
                                  //         "Model: " +
                                  //           multipleRes.filter(
                                  //             (i) =>
                                  //               i.result[0].id_line ===
                                  //               u.id_line
                                  //           )[0].result[0].modelCheck +
                                  //           "\n" +
                                  //           "RAM: " +
                                  //           multipleRes.filter(
                                  //             (i) =>
                                  //               i.result[0].id_line ===
                                  //               u.id_line
                                  //           )[0].result[0].RAMcheck
                                  //       );
                                  //       setOpenModal(true);
                                  //     }}
                                  //   >
                                  //     <IconAlertTriangleFilled
                                  //       width={18}
                                  //       height={18}
                                  //       style={{ color: "orange" }}
                                  //     />
                                  //   </IconButton>
                                  // </Tooltip>
                                ) : // console.log(multipleRes.filter((i)=>i.result[0].RAMcheck!==""))
                                multipleRes.filter(
                                    (i) => i.result[0].id_line === u.id_line
                                  )[0].result[0].modelCheck !== undefined &&
                                  multipleRes.filter(
                                    (i) => i.result[0].id_line === u.id_line
                                  )[0].result[0].modelCheck !== "" ? (
                                  <Tooltip title="Warning">
                                    <IconButton
                                      style={{
                                        padding: 2,
                                        margin: "0 0 0 5px",
                                        zIndex: 1000,
                                      }}
                                      onClick={() => {
                                        setText(
                                          "Model: " +
                                            multipleRes.filter(
                                              (i) =>
                                                i.result[0].id_line ===
                                                u.id_line
                                            )[0].result[0].modelCheck +
                                            "\n" +
                                            "RAM: " +
                                            multipleRes.filter(
                                              (i) =>
                                                i.result[0].id_line ===
                                                u.id_line
                                            )[0].result[0].RAMcheck
                                        );
                                        setOpenModal(true);
                                      }}
                                    >
                                      <IconAlertTriangleFilled
                                        width={18}
                                        height={18}
                                        style={{ color: "orange" }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                ) : (
                                  ""
                                )
                              ) : (
                                ""
                              )
                            ) : (
                              ""
                            )
                          } */}
                        </div>
                      </div>
                      {/* STATUS */}
                      <div
                        style={{
                          width: "10%",
                          position: "absolute",
                          left: "7.5%",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "0.85rem",
                          height: "100%",
                          display:
                            listTestAuto.filter((t) => t.id === u.id_line)
                              .length > 0
                              ? //   ||
                                // listLastLogs.filter(
                                //   (i) => i.log_line === u.id_line
                                // )[0]?.log_status === "loadingIOS"
                                // || listLastLogs.filter((i)=>i.log_line===u.id_line)[0]?.log_status === "Wait"
                                "flex"
                              : "none",
                        }}
                      >
                        {listTimer.filter((i) => i.id_line === u.id_line)
                          .length > 0 ? (
                          <Tooltip title="Click to cancel action">
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                fontWeight: 700,
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                cursor: "pointer",
                                fontSize: "0.7rem",
                              }}
                              onClick={() => {
                                socket?.emit("cancelAction", {
                                  clear_line: u.line_clear,
                                  id: u.id_line,
                                });
                                cancelAction(u.line_clear, u.line_port);
                              }}
                            >
                              {/* {listTimer.filter((i) => i.id_line === u.id_line).length > 0 ? */}
                              <span style={{ color: "blueviolet" }}>
                                {" "}
                                {
                                  listTimer.filter(
                                    (i) => i.id_line === u.id_line
                                  )[0].action
                                }
                              </span>
                              {/* : ""} */}
                              {/* {listTimer.filter((i) => i.id_line === u.id_line).length > 0 ? */}
                              <div style={{ textAlign: "center" }}>
                                <Timer
                                  deadline={
                                    listTimer.filter(
                                      (i) => i.id_line === u.id_line
                                    )[0].time
                                  }
                                />
                              </div>
                              {/* : ""} */}
                            </div>
                          </Tooltip>
                        ) : listLastLogs.length > 0 &&
                          listLastLogs.filter(
                            (i) => i.log_line === u.id_line
                          )[0] !== undefined ? (
                          <Button
                            size="small"
                            color={
                              listLastLogs
                                .filter((i) => i.log_line === u.id_line)[0]
                                .log_status.search("DPEL") !== -1
                                ? "success"
                                : listLastLogs.filter(
                                    (i) => i.log_line === u.id_line
                                  )[0].log_status === "Faulty"
                                ? "warning"
                                : listLastLogs.filter(
                                    (i) => i.log_line === u.id_line
                                  )[0].log_status === "Wait" ||
                                  listLastLogs.filter(
                                    (i) => i.log_line === u.id_line
                                  )[0].log_status === "loadingIOS"
                                ? "info"
                                : "error"
                            }
                            sx={{
                              fontWeight: "800",
                              height: "20px",
                              fontSize: "0.8rem",
                              animationName:
                                listLastLogs.filter(
                                  (i) => i.log_line === u.id_line
                                )[0].log_status === "Faulty"
                                  ? "status-faulty"
                                  : "none",
                              animationDuration:
                                listLastLogs.filter(
                                  (i) => i.log_line === u.id_line
                                )[0].log_status === "Faulty"
                                  ? "2s"
                                  : "none",
                              animationIterationCount:
                                listLastLogs.filter(
                                  (i) => i.log_line === u.id_line
                                )[0].log_status === "Faulty"
                                  ? "infinite"
                                  : "none",
                            }}
                            onClick={() => {
                              setLine(u);
                              setIsLoading();
                              setOpenHistory(false);
                              setOpenArea(false);
                              setOpenModal(true);
                              setText(
                                listLastLogs.filter(
                                  (i) => i.log_line === u.id_line
                                )[0].log_content
                              );
                              setSelectText(
                                listLastLogs.filter(
                                  (i) => i.log_line === u.id_line
                                )[0].log_content
                              );
                            }}
                          >
                            <>
                              {" "}
                              {
                                listLastLogs
                                  .filter((i) => i.log_line === u.id_line)[0]
                                  .log_status.split("--")[0]
                              }
                            </>
                          </Button>
                        ) : (
                          ""
                        )}
                      </div>
                      {/* PID */}
                      <div
                        style={{
                          width: "9.7%",
                          position: "absolute",
                          left: "16.5%",
                          fontSize: "0.85rem",
                          alignItems: "center",
                          justifyContent: "left",
                          height: "100%",
                          display:
                            listTestAuto.filter((t) => t.id === u.id_line)
                              .length > 0 &&
                            listTimer.filter((t) => t.id_line === u.id_line)
                              .length === 0
                              ? "flex"
                              : "none",
                          // border:"solid 1px"
                        }}
                      >
                        {listLastLogs.length > 0 &&
                        listLastLogs.filter(
                          (i) => i.log_line === u.id_line
                        )[0] !== undefined &&
                        listLastLogs.filter((i) => i.log_line === u.id_line)[0]
                          .PID !== "" ? (
                          <>
                            {
                              listLastLogs.filter(
                                (i) => i.log_line === u.id_line
                              )[0].PID
                            }
                            <IconButton
                              style={{
                                width: 32,
                                height: 25,
                                marginTop: "-0.5vh",
                              }}
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  listLastLogs.filter(
                                    (i) => i.log_line === u.id_line
                                  )[0].PID
                                );
                              }}
                            >
                              <IconCopy
                                width={40}
                                height={40}
                                color="rgb(0, 105, 167)"
                              />
                            </IconButton>
                          </>
                        ) : (
                          listLastLogs.filter(
                            (i) =>
                              i.log_status === "ERROR" &&
                              i.log_line === u.id_line
                          )[0] !== undefined && (
                            <>
                              {
                                listCisco.filter(
                                  (i) =>
                                    listLastLogs
                                      .filter(
                                        (i) => i.log_line === u.id_line
                                      )[0]
                                      .log_content?.search("=" + i) !== -1
                                )[0]
                              }
                              <IconButton
                                style={{
                                  width: 32,
                                  height: 25,
                                  marginTop: "-0.5vh",
                                  display:
                                    listCisco.filter(
                                      (i) =>
                                        listLastLogs
                                          .filter(
                                            (i) => i.log_line === u.id_line
                                          )[0]
                                          .log_content?.search("=" + i) !== -1
                                    )[0] !== undefined
                                      ? "flex"
                                      : "none",
                                }}
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    listCisco.filter(
                                      (i) =>
                                        listLastLogs
                                          .filter(
                                            (i) => i.log_line === u.id_line
                                          )[0]
                                          .log_content?.search("=" + i) !== -1
                                    )[0]
                                  );
                                }}
                              >
                                <IconCopy
                                  width={40}
                                  height={40}
                                  color="rgb(0, 105, 167)"
                                />
                              </IconButton>
                            </>
                          )
                        )}

                        {listLastLogs.filter(
                          (i) => i.log_line === u.id_line
                        )[0] !== undefined &&
                        listLastLogs.filter((i) => i.log_line === u.id_line)[0]
                          .PID === undefined ? (
                          <>
                            {
                              listLastLogs.filter(
                                (i) => i.log_line === u.id_line
                              )[0].PID
                            }
                            <IconButton
                              style={{
                                width: 32,
                                height: 25,
                                marginTop: "-0.5vh",
                              }}
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  listLastLogs.filter(
                                    (i) => i.log_line === u.id_line
                                  )[0].PID
                                );
                              }}
                            >
                              <IconCopy
                                width={40}
                                height={40}
                                color="rgb(0, 105, 167)"
                              />
                            </IconButton>
                          </>
                        ) : listLastLogs.filter(
                            (i) =>
                              i.log_status === "ROMMON:" &&
                              i.log_line === u.id_line
                          )[0] !== undefined ? (
                          listLastLogs
                            .filter((i) => i.log_line === u.id_line)[0]
                            .log_content.split("\n")[1]
                            .split("|||")[1] !== "" &&
                          listLastLogs
                            .filter((i) => i.log_line === u.id_line)[0]
                            .log_content.split("\n")[1]
                            .split("|||")[1] !== undefined ? (
                            <>
                              {
                                listLastLogs
                                  .filter((i) => i.log_line === u.id_line)[0]
                                  .log_content.split("\n")[1]
                                  .split("|||")[1]
                              }
                              <IconButton
                                style={{
                                  width: 32,
                                  height: 25,
                                  marginTop: "-0.5vh",
                                }}
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    listLastLogs
                                      .filter(
                                        (i) => i.log_line === u.id_line
                                      )[0]
                                      .log_content.split("\n")[1]
                                      .split("|||")[1]
                                  );
                                }}
                              >
                                <IconCopy
                                  width={40}
                                  height={40}
                                  color="rgb(0, 105, 167)"
                                />
                              </IconButton>
                            </>
                          ) : (
                            ""
                          )
                        ) : listLastLogs.filter(
                            (i) =>
                              i.log_status === "SWITCH:" &&
                              i.log_line === u.id_line
                          )[0] !== undefined ? (
                          listLastLogs
                            .filter((i) => i.log_line === u.id_line)[0]
                            .log_content.split("\n")[1]
                            .split("|||")[1] !== "" &&
                          listLastLogs
                            .filter((i) => i.log_line === u.id_line)[0]
                            .log_content.split("\n")[1]
                            .split("|||")[1] !== undefined ? (
                            <>
                              {
                                listLastLogs
                                  .filter((i) => i.log_line === u.id_line)[0]
                                  .log_content.split("\n")[1]
                                  .split("|||")[1]
                              }
                              <IconButton
                                style={{
                                  width: 32,
                                  height: 25,
                                  marginTop: "-0.5vh",
                                }}
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    listLastLogs
                                      .filter(
                                        (i) => i.log_line === u.id_line
                                      )[0]
                                      .log_content.split("\n")[1]
                                      .split("|||")[1]
                                  );
                                }}
                              >
                                <IconCopy
                                  width={40}
                                  height={40}
                                  color="rgb(0, 105, 167)"
                                />
                              </IconButton>
                            </>
                          ) : (
                            ""
                          )
                        ) : listLastLogs.filter(
                            (i) =>
                              i.log_status === "PASSWORD" &&
                              i.log_line === u.id_line
                          )[0] !== undefined &&
                          listLastLogs
                            .filter((i) => i.log_line === u.id_line)[0]
                            .log_content.split("\n")[1]
                            .split("--")[1] !== undefined ? (
                          listLastLogs
                            .filter((i) => i.log_line === u.id_line)[0]
                            .log_content.split("\n")[1]
                            .split("--")[1] !== "" &&
                          listLastLogs
                            .filter((i) => i.log_line === u.id_line)[0]
                            .log_content.split("\n")[1]
                            .split("--")[1] !== undefined ? (
                            <>
                              {
                                listLastLogs
                                  .filter((i) => i.log_line === u.id_line)[0]
                                  .log_content.split("\n")[1]
                                  .split("--")[1]
                              }
                              <IconButton
                                style={{
                                  width: 32,
                                  height: 25,
                                  marginTop: "-0.5vh",
                                }}
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    listLastLogs
                                      .filter(
                                        (i) => i.log_line === u.id_line
                                      )[0]
                                      .log_content.split("\n")[1]
                                      .split("--")[1]
                                      .trim()
                                  );
                                }}
                              >
                                <IconCopy
                                  width={40}
                                  height={40}
                                  color="rgb(0, 105, 167)"
                                />
                              </IconButton>
                            </>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                      </div>
                      {/* VID */}
                      <div
                        style={{
                          width: "4%",
                          position: "absolute",
                          left: "26.3%",
                          height: "100%",
                          fontSize: "0.85rem",
                          alignItems: "center",
                          justifyContent: "center",
                          // border:"solid 1px",
                          animationName:
                            listLastLogs.filter(
                              (i) => i.log_line === u.id_line
                            )[0] !== undefined &&
                            listLastLogs
                              .filter((i) => i.log_line === u.id_line)[0]
                              ?.log_content.split("\n")
                              .filter(
                                (i) =>
                                  i.search("PID:") !== -1 &&
                                  i.search("VID:") !== -1 &&
                                  i.search("SN:") !== -1
                              )[0]
                              ?.split("VID:")[1]
                              ?.split("SN:")[0]
                              .replace(",", "")
                              .trim().search("V00") !== -1
                              ? "V00"
                              : "none",
                          animationDuration:
                            listLastLogs.filter(
                              (i) => i.log_line === u.id_line
                            )[0] !== undefined &&
                            listLastLogs
                              .filter((i) => i.log_line === u.id_line)[0]
                              ?.log_content.split("\n")
                              .filter(
                                (i) =>
                                  i.search("PID:") !== -1 &&
                                  i.search("VID:") !== -1 &&
                                  i.search("SN:") !== -1
                              )[0]
                              ?.split("VID:")[1]
                              ?.split("SN:")[0]
                              .replace(",", "")
                              .trim().search("V00")
                              ? "2s"
                              : "none",
                          animationIterationCount:
                            listLastLogs.filter(
                              (i) => i.log_line === u.id_line
                            )[0] !== undefined &&
                            listLastLogs
                              .filter((i) => i.log_line === u.id_line)[0]
                              ?.log_content.split("\n")
                              .filter(
                                (i) =>
                                  i.search("PID:") !== -1 &&
                                  i.search("VID:") !== -1 &&
                                  i.search("SN:") !== -1
                              )[0]
                              ?.split("VID:")[1]
                              ?.split("SN:")[0]
                              .replace(",", "")
                              .trim().search("V00") !== -1
                              ? "infinite"
                              : "none",
                          display:
                            listTestAuto.filter((t) => t.id === u.id_line)
                              .length > 0 &&
                            listTimer.filter((t) => t.id_line === u.id_line)
                              .length === 0 &&
                            listLastLogs
                              .filter((i) => i.log_line === u.id_line)[0]
                              ?.log_content.split("\n")
                              .filter((i) => i.search("VID:") !== -1).length > 0
                              ? "flex"
                              : "none",
                        }}
                      >
                        {listLastLogs.length > 0 &&
                        listLastLogs.filter(
                          (i) => i.log_line === u.id_line
                        )[0] !== undefined &&
                        listLastLogs
                          .filter((i) => i.log_line === u.id_line)[0]
                          .log_content.search("VID:") !== -1
                          ? listLastLogs
                              .filter((i) => i.log_line === u.id_line)[0]
                              .log_content?.split("VID:")[1]?.split("SN:")[0]?.replace(",","").trim()
                              // slice(
                              //   listLastLogs
                              //     .filter((i) => i.log_line === u.id_line)[0]
                              //     .log_content.search("VID:") + 4,
                              //   listLastLogs
                              //     .filter((i) => i.log_line === u.id_line)[0]
                              //     .log_content.search("VID:") + 8
                              // )
                          : ""}
                      </div>
                      {/* SN */}
                      <div
                        style={{
                          textAlign: "center",
                          position: "absolute",
                          left: "30.3%",
                          fontSize: "0.85rem",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "100%",
                          width: "9%",
                          display:
                            listTestAuto.filter((t) => t.id === u.id_line)
                              .length > 0 &&
                            listTimer.filter((t) => t.id_line === u.id_line)
                              .length === 0
                              ? "flex"
                              : "none",
                          // border:"solid 1px",
                        }}
                      >
                        {listLastLogs.length > 0 &&
                        listLastLogs.filter(
                          (i) => i.log_line === u.id_line
                        )[0] !== undefined &&
                        listLastLogs.filter((i) => i.log_line === u.id_line)[0]
                          .SN !== "" ? (
                          <>
                            {
                              listLastLogs.filter(
                                (i) => i.log_line === u.id_line
                              )[0].SN
                            }
                            <IconButton
                              style={{
                                width: 32,
                                height: 25,
                                marginTop: "-0.5vh",
                              }}
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  listLastLogs.filter(
                                    (i) => i.log_line === u.id_line
                                  )[0].SN
                                );
                              }}
                            >
                              <IconCopy
                                width={40}
                                height={40}
                                color="rgb(0, 105, 167)"
                              />
                            </IconButton>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      {/* CREATED AT */}
                      <div
                        style={{
                          position: "absolute",
                          left: "39.5%",
                          fontSize: "0.85rem",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "100%",
                          width: "7.5%",
                          display:
                            listTestAuto.filter((t) => t.id === u.id_line)
                              .length > 0 &&
                            listTimer.filter((t) => t.id_line === u.id_line)
                              .length === 0
                              ? "flex"
                              : "none",
                          // border:"solid 1px",
                        }}
                      >
                        {listLastLogs.length > 0 &&
                        listLastLogs.filter(
                          (i) => i.log_line === u.id_line
                        )[0] !== undefined
                          ? moment(
                              parseInt(
                                listLastLogs.filter(
                                  (i) => i.log_line === u.id_line
                                )[0].created_at
                              )
                            ).format("HH:mm:ss-D/M/YY")
                          : ""}
                      </div>
                      {/* ACTION */}
                      <div
                        style={{
                          textAlign: "center",
                          position: "absolute",
                          left: "47%",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "100%",
                          width: "26%",
                          display:
                            listTestAuto.filter((t) => t.id === u.id_line)
                              .length > 0 &&
                            listTimer.filter((t) => t.id_line === u.id_line)
                              .length === 0
                              ? "flex"
                              : listDeviceOff.filter((i) => i.id === u.id_line)
                                  .length > 0 &&
                                listTimer.filter((t) => t.id_line === u.id_line)
                                  .length === 0
                              ? "flex"
                              : "none",
                          // border:"solid 1px",
                        }}
                      >
                        {/* PASSWORD */}
                        {
                          listDeviceOff.filter((i) => i.id === u.id_line)
                            .length > 0
                            ? listTestAuto.filter((t) => t.id === u.id_line)
                                .length === 0 &&
                              listDeviceOff.filter((i) => i.id === u.id_line)[0]
                                .message
                            : listLastLogs.length > 0 &&
                              listTestAuto.filter((t) => t.id === u.id_line)
                                .length > 0 &&
                              listLastLogs.filter(
                                (i) =>
                                  i.log_status === "PASSWORD" &&
                                  i.log_line === u.id_line
                              )[0] !== undefined && (
                                <span
                                  style={{
                                    color: "orange",
                                    fontSize: "0.85rem",
                                    fontWeight: "700",
                                    textAlign: "center",
                                  }}
                                >
                                  (On-site tester) Please repower and hold mode
                                  button
                                </span>
                              )
                          // ) : (
                          //   <div
                          //     style={{
                          //       color: "orangered",
                          //       fontSize: "0.8rem",
                          //       fontWeight: "700",
                          //       textAlign: "center",
                          //     }}
                          //   >
                          //     Notify admin to check and break{" "}
                          //     {
                          //       listLastLogs
                          //         .filter((i) => i.log_line === u.id_line)[0]
                          //         .log_content.split("\n")[1]
                          //         .split("--")[1]
                          //     }
                          //   </div>
                          // )
                          // ) : (
                          //   ""
                        }
                        {/* Rommon
                        {listLastLogs.length > 0 && listTestAuto.filter((t) => t.id === u.id_line)
                        .length > 0&&
                        listLastLogs.filter(
                          (i) =>
                            i.log_status === "ROMMON:" &&
                            i.log_line === u.id_line
                        )[0] !== undefined ? (
                          <div
                            style={{
                              color: "orangered",
                              fontSize: "0.8rem",
                              fontWeight: "700",
                              textAlign: "center",
                            }}
                          >
                            Admin please check -{" "}
                            {
                              listLastLogs
                                .filter((i) => i.log_line === u.id_line)[0]
                                .log_content.split("\n")[1]
                                .split("|||")[1]
                            }{" "}
                            can't boot
                          </div>
                        ) : (
                          ""
                        )} */}

                        {/* Switch:
                        {listLastLogs.length > 0 &&listTestAuto.filter((t) => t.id === u.id_line)
                        .length > 0&&
                        listLastLogs.filter(
                          (i) =>
                            i.log_status === "SWITCH:" &&
                            i.log_line === u.id_line
                        )[0] !== undefined ? (
                          <div
                            style={{
                              color: "orangered",
                              fontSize: "0.8rem",
                              fontWeight: "700",
                              textAlign: "center",
                            }}
                          >
                            Admin please check -{" "}
                            {listLastLogs.filter(
                              (i) => i.log_line === u.id_line
                            )[0].log_content !== undefined &&
                              listLastLogs
                                .filter((i) => i.log_line === u.id_line)[0]
                                .log_content.split("\n")[1]
                                .split("|||")[1]}{" "}
                            can't boot
                          </div>
                        ) : (
                          ""
                        )} */}

                        {/* Faulty: */}
                        {listLastLogs.length > 0 &&
                        listTestAuto.filter((t) => t.id === u.id_line).length >
                          0 &&
                        listLastLogs.filter(
                          (i) =>
                            i.log_status === "Faulty" &&
                            i.log_line === u.id_line
                        )[0] !== undefined ? (
                          <marquee
                            style={{
                              color: "orange",
                              fontSize: "0.85rem",
                              fontWeight: "700",
                              textAlign: "center",
                              width: "100%",
                            }}
                            scrollamount="4"
                          >
                            (Remote tester) Please check {"--->"}{" "}
                            {listLastLogs
                              .filter(
                                (i) =>
                                  i.log_status === "Faulty" &&
                                  i.log_line === u.id_line
                              )[0]
                              .log_content.slice(
                                listLastLogs
                                  .filter(
                                    (i) =>
                                      i.log_status === "Faulty" &&
                                      i.log_line === u.id_line
                                  )[0]
                                  .log_content.search("Faulty"),
                                listLastLogs
                                  .filter(
                                    (i) =>
                                      i.log_status === "Faulty" &&
                                      i.log_line === u.id_line
                                  )[0]
                                  .log_content.search("####") - 10
                              )
                              .replace(/=/g, "")
                              .replace("\n\n", " | ")}
                          </marquee>
                        ) : (
                          ""
                        )}
                        {/* Good: */}
                        {listLastLogs.length > 0 &&
                        listTestAuto.filter((t) => t.id === u.id_line).length >
                          0 &&
                        listLastLogs.filter(
                          (i) =>
                            i.log_status.search("DPEL") !== -1 &&
                            i.log_line === u.id_line
                        )[0] !== undefined ? (
                          <div style={{ width: "100%", textAlign: "center" }}>
                            {
                              listTestAuto.filter((t) => t.id === u.id_line)
                                .length > 0 && (
                                // listTestAuto.filter((t) => t.id === u.id_line)[0]
                                //   ?.interfaceCheck !== undefined ? (
                                //   <div
                                //     style={{
                                //       fontSize: "0.7rem",
                                //       fontWeight: "700",
                                //       textAlign: "left",
                                //       display: "flex",
                                //       flexFlow: "column",
                                //       // width:"300px"
                                //     }}
                                //   >
                                //     <p style={{ color: "green", width: "100%" }}>
                                //       {/* <marquee> */}
                                //       {listLastLogs
                                //         .filter(
                                //           (i) =>
                                //             i.log_status.search("DPEL") !== -1 &&
                                //             i.log_line === u.id_line
                                //         )[0]
                                //         .log_status.split("--")[1] +
                                //         " (detected " +
                                //         listTestAuto.filter(
                                //           (t) => t.id === u.id_line
                                //         )[0]?.interfaceCheck?.interUp?.length +
                                //         "/up " +
                                //         listTestAuto.filter(
                                //           (t) => t.id === u.id_line
                                //         )[0]?.interfaceCheck?.interUpFinal?.length +
                                //         "/down " +
                                //         listTestAuto.filter(
                                //           (t) => t.id === u.id_line
                                //         )[0]?.interfaceCheck?.interDown?.length +
                                //         ")"}
                                //       {/* </marquee> */}
                                //     </p>
                                //     <span
                                //       style={{
                                //         width: "100%",
                                //         fontSize: "0.8rem",
                                //         color: "orange",
                                //       }}
                                //     >
                                //       {listTestAuto.filter(
                                //         (t) => t.id === u.id_line
                                //       )[0]?.interfaceCheck?.interDown.length > 6 ? (
                                //         <marquee scrollamount="4">
                                //           {listTestAuto
                                //             .filter((t) => t.id === u.id_line)[0]
                                //             ?.interfaceCheck?.interDown?.join(", ")}
                                //         </marquee>
                                //       ) : (
                                //         listTestAuto
                                //           .filter((t) => t.id === u.id_line)[0]
                                //           ?.interfaceCheck?.interDown?.join(", ")
                                //       )}
                                //     </span>
                                //   </div>
                                // ) : (
                                <span
                                  style={{
                                    color: "green",
                                    fontSize: "0.8rem",
                                    fontWeight: "700",
                                    textAlign: "center",
                                  }}
                                >
                                  {
                                    listLastLogs
                                      .filter(
                                        (i) =>
                                          i.log_status.search("DPEL") !== -1 &&
                                          i.log_line === u.id_line
                                      )[0]
                                      .log_status.split("--")[1]
                                  }
                                </span>
                              )
                              // )
                            }
                          </div>
                        ) : (
                          ""
                        )}

                        {/* Error */}
                        {listLastLogs.length > 0 &&
                        listTestAuto.filter((t) => t.id === u.id_line).length >
                          0 &&
                        listLastLogs.filter(
                          (i) =>
                            i.log_status.search("Error") !== -1 &&
                            i.log_line === u.id_line
                        )[0] !== undefined ? (
                          <div
                            style={{
                              color: "orangered",
                              fontSize: "0.85rem",
                              fontWeight: "700",
                              textAlign: "center",
                            }}
                          >
                            (Remote tester) Please check
                          </div>
                        ) : listLastLogs.length > 0 &&
                          listTestAuto.filter((t) => t.id === u.id_line)
                            .length > 0 &&
                          listLastLogs.filter(
                            (i) =>
                              i.log_status.search("ERROR") !== -1 &&
                              i.log_line === u.id_line
                          )[0] !== undefined ? (
                          <div
                            style={{
                              color: "orangered",
                              fontSize: "0.85rem",
                              fontWeight: "700",
                              textAlign: "center",
                            }}
                          >
                            (Remote tester) Error loading IOS. Please install
                            IOS image
                          </div>
                        ) : (
                          ""
                        )}

                        {/* Wait */}
                        {listLastLogs.length > 0 &&
                        listTestAuto.filter((t) => t.id === u.id_line).length >
                          0 &&
                        listLastLogs.filter(
                          (i) =>
                            i.log_status === "Wait" && i.log_line === u.id_line
                        )[0] !== undefined ? (
                          <div
                            style={{
                              color: "blue",
                              fontSize: "0.8rem",
                              fontWeight: "700",
                              textAlign: "center",
                            }}
                          >
                            Wait for automatic test
                          </div>
                        ) : (
                          ""
                        )}

                        {/* Loading IOS */}
                        {listLastLogs.length > 0 &&
                        listTestAuto.filter((t) => t.id === u.id_line).length >
                          0 &&
                        listLastLogs.filter(
                          (i) =>
                            i.log_status === "loadingIOS" &&
                            i.log_line === u.id_line
                        )[0] !== undefined ? (
                          <div
                            style={{
                              color: "blue",
                              fontSize: "0.8rem",
                              fontWeight: "700",
                              display: "flex",
                              width: "100%",
                              justifyContent: "center",
                              justifyItems: "center",
                            }}
                          >
                            <SM
                              data={
                                getListIOS(
                                  listLastLogs.filter(
                                    (i) =>
                                      i.log_status === "loadingIOS" &&
                                      i.log_line === u.id_line
                                  )[0]?.log_content
                                )?.length > 0
                                  ? getListIOS(
                                      listLastLogs.filter(
                                        (i) =>
                                          i.log_status === "loadingIOS" &&
                                          i.log_line === u.id_line
                                      )[0]?.log_content
                                    )
                                  : ["None"]
                              }
                              placeholder="Select IOS"
                              style={{ width: "90%" }}
                              value={
                                selectIOS.filter((i) => i.key === u.id_line)[0]
                                  ? selectIOS.filter(
                                      (i) => i.key === u.id_line
                                    )[0]?.value
                                  : ""
                              }
                              onChange={(e) => {
                                let index = selectIOS.findIndex(
                                  (obj) => obj.key === u.id_line
                                );
                                if (index !== -1) {
                                  const temp = [...selectIOS];
                                  temp[index].value = e;
                                  setselectIOS(temp);
                                } else {
                                  setselectIOS((prevArray) => [
                                    ...prevArray,
                                    { key: u.id_line, value: e },
                                  ]);
                                }
                              }}
                            ></SM>
                            <IconButton
                              onClick={() => {
                                if (
                                  selectIOS.filter((i) => i.key === u.id_line)
                                    .length === 0
                                ) {
                                  toast.error("Please select IOS!");
                                } else {
                                  axios.post(installIOS, {
                                    ios_image: selectIOS.filter(
                                      (i) => i.key === u.id_line
                                    )[0]?.value,
                                    listLine: u,
                                    infoStation: infoStation,
                                    content: listLastLogs.filter(
                                      (i) =>
                                        i.log_status === "loadingIOS" &&
                                        i.log_line === u.id_line
                                    )[0]?.log_content,
                                  });
                                  // }

                                  socket?.emit("sendListTimer", [
                                    {
                                      id_line: u.id_line,
                                      time: Date(Date.now()),
                                      action: "loading IOS",
                                      timeCheck: Date.now(),
                                    },
                                  ]);
                                  socket?.emit("sendListRun", [u]);
                                }
                              }}
                            >
                              <PlayCircleOutlineIcon color="success" />
                            </IconButton>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      {/* NOTE */}
                      <div
                        style={{
                          textAlign: "center",
                          position: "absolute",
                          left: "73%",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "100%",
                          width: "15%",
                          display: "flex",
                        }}
                      >
                        {listLastLogs.filter(
                          (i) => i.log_line === u.id_line
                        )[0] !== undefined ? (
                          listLastLogs.filter(
                            (i) => i.log_line === u.id_line
                          )[0].command !== null ? (
                            <div
                              style={{
                                color: "black",
                                fontSize: "0.8rem",
                                fontWeight: "700",
                                animationName: "note",
                                animationDuration: "2s",
                                animationIterationCount: "infinite",
                                borderBottom: "solid 0.2px gray",
                                height: "100%",
                                width: "96%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {
                                listLastLogs.filter(
                                  (i) => i.log_line === u.id_line
                                )[0].command
                              }
                            </div>
                          ) : (
                            <Input
                              size="xs"
                              style={{
                                margin: "0.3vh 0 0 0",
                                color: "rgb(0, 105, 167)",
                                fontWeight: 700,
                                width: "100%",
                                height: "100%",
                              }}
                              placeholder={"Press 'Enter' to commit"}
                              value={
                                listLastLogs.filter(
                                  (i) => i.log_line === u.id_line
                                )[0] !== undefined
                                  ? listLastLogs.filter(
                                      (i) => i.log_line === u.id_line
                                    )[0].command
                                  : ""
                              }
                              // disabled={
                              //   listLastLogs.filter(
                              //     (i) => i.log_line === u.id_line
                              //   )[0] !== undefined &&
                              //   listLastLogs.filter(
                              //     (i) => i.log_line === u.id_line
                              //   )[0].command === null
                              //     ? false
                              //     : true
                              // }
                              onKeyDown={async (e) => {
                                if (e.key === "Enter") {
                                  handleCommand(u.id_line, e.target.value);
                                  toast.success("Add note success!");
                                  let infoLog = listLastLogs.filter(
                                    (i) => i.log_line === u.id_line
                                  )[0];
                                  socket?.emit("sendNoteToZulip", {
                                    sta_name: infoStation.sta_name,
                                    line: u.line_number,
                                    status: infoLog?.log_status.split("--")[0],
                                    output: infoLog?.log_content,
                                    PID_SN: infoLog?.log_content
                                      ?.split("\n")
                                      ?.filter(
                                        (i) =>
                                          i.search("PID:") !== -1 &&
                                          i.search("SN:") !== -1
                                      )[0]
                                      ?.replace(/\r/g, ""),
                                    note: e.target.value,
                                    time: moment(
                                      parseInt(infoLog?.created_at)
                                    ).format("DD/MM"),
                                    file: infoLog?.fileName?.split("/")[
                                      infoLog?.fileName?.split("/").length - 1
                                    ],
                                  });
                                }
                              }}
                              onChange={(e) => {}}
                            ></Input>
                          )
                        ) : (
                          ""
                        )}
                      </div>

                      <div
                        style={{
                          textAlign: "center",
                          position: "absolute",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "100%",
                          left: "86.5%",
                          cursor: "pointer",
                          display:
                            listLastLogs.filter(
                              (i) => i.log_line === u.id_line
                            )[0] !== undefined
                              ? listLastLogs.filter(
                                  (i) => i.log_line === u.id_line
                                )[0].command === null
                                ? "none"
                                : "flex"
                              : "none",
                        }}
                      >
                        <Tooltip title="Clear note" placement="left">
                          <BackspaceIcon
                            style={{ height: 25, width: 18, color: "gray" }}
                            onClick={async () => {
                              handleCommand(u.id_line, null);
                            }}
                          />
                        </Tooltip>
                      </div>

                      <div
                        style={{
                          position: "absolute",
                          left: "88%",
                          fontSize: "0.8rem",
                          width: "10%",
                          height: "100%",
                          fontWeight: 700,
                          backgroundColor: "#93ff00",
                          animationName: "notiItem",
                          animationDuration: "2s",
                          animationIterationCount: "infinite",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "100%",
                          display:
                            // listTestAuto.filter((i) => i.id === u.id_line)
                            //   .length > 0 &&
                            // listTimer.filter((t) => t.id_line === u.id_line)
                            //   .length === 0
                            //   ? listLastLogs.filter(
                            //       (i) => i.log_line === u.id_line
                            //     )[0] !== undefined &&
                            //     listLastLogs.filter(
                            //       (i) => i.log_line === u.id_line
                            //     )[0].log_content !== "" &&
                            //     specialMoel.filter(
                            //       (i) =>
                            //         getPIDItem(
                            //           listLastLogs.filter(
                            //             (i) => i.log_line === u.id_line
                            //           )[0].log_content
                            //         )
                            //           .split("\n")
                            //           .slice(3)
                            //           .toString()
                            //           .search(i) !== -1
                            //     ).length > 0 &&
                            //     listTimer.filter((t) => t.id_line === u.id_line)
                            //       .length === 0
                            //     ? getPIDItem(
                            //         listLastLogs.filter(
                            //           (i) => i.log_line === u.id_line
                            //         )[0].log_content
                            //       ).split("\n").length > 8
                            listLastLogs.filter(
                              (i) => i.log_line === u.id_line
                            )[0]!==undefined&&listLastLogs.filter(
                              (i) => i.log_line === u.id_line
                            )[0]?.log_content.search("Extra items:")!==-1?
                           
                            "flex"
                                  // : "none"
                                : RAMCheck.length > 0 &&
                                  RAMCheck.filter(
                                    (i) =>
                                      listLastLogs.filter(
                                        (i) => i.log_line === u.id_line
                                      )[0] !== undefined &&
                                      listLastLogs
                                        .filter(
                                          (i) => i.log_line === u.id_line
                                        )[0]
                                        .PID.search(i.split(":")[0]) !== -1
                                  )[0] !== undefined &&
                                  (parseInt(
                                    RAMCheck.filter(
                                      (i) =>
                                        listLastLogs.filter(
                                          (i) => i.log_line === u.id_line
                                        )[0] !== undefined &&
                                        listLastLogs
                                          .filter(
                                            (i) => i.log_line === u.id_line
                                          )[0]
                                          .PID.search(i.split(":")[0]) !== -1
                                    )[0].split(":")[1]
                                  ) *
                                    105) /
                                    100 <
                                    getRAMDevice(
                                      listLastLogs.filter(
                                        (i) => i.log_line === u.id_line
                                      )[0] !== undefined &&
                                        listLastLogs.filter(
                                          (i) => i.log_line === u.id_line
                                        )[0].log_content !== ""
                                        ? listLastLogs.filter(
                                            (i) => i.log_line === u.id_line
                                          )[0].log_content
                                        : ""
                                    )
                                ? "flex"
                                : "none",
                              // : "none",
                          color: "#001bff",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setText(
                            RAMCheck.length > 0 &&
                              RAMCheck.filter(
                                (i) =>
                                  listLastLogs.filter(
                                    (i) => i.log_line === u.id_line
                                  )[0] !== undefined &&
                                  listLastLogs
                                    .filter((i) => i.log_line === u.id_line)[0]
                                    .PID.search(i.split(":")[0]) !== -1
                              )[0] !== undefined &&
                              (parseInt(
                                RAMCheck.filter(
                                  (i) =>
                                    listLastLogs.filter(
                                      (i) => i.log_line === u.id_line
                                    )[0] !== undefined &&
                                    listLastLogs
                                      .filter(
                                        (i) => i.log_line === u.id_line
                                      )[0]
                                      .PID.search(i.split(":")[0]) !== -1
                                )[0].split(":")[1]
                              ) *
                                105) /
                                100 <
                                getRAMDevice(
                                  listLastLogs.filter(
                                    (i) => i.log_line === u.id_line
                                  )[0] !== undefined &&
                                    listLastLogs.filter(
                                      (i) => i.log_line === u.id_line
                                    )[0].log_content !== ""
                                    ? listLastLogs.filter(
                                        (i) => i.log_line === u.id_line
                                      )[0].log_content
                                    : ""
                                )
                              ? "RAM: " +
                                  (
                                    getRAMDevice(
                                      listLastLogs.filter(
                                        (i) => i.log_line === u.id_line
                                      )[0] !== undefined &&
                                        listLastLogs.filter(
                                          (i) => i.log_line === u.id_line
                                        )[0].log_content !== ""
                                        ? listLastLogs.filter(
                                            (i) => i.log_line === u.id_line
                                          )[0].log_content
                                        : ""
                                    ) /
                                    1024 /
                                    1024
                                  )
                                    .toFixed(2)
                                    .toString() +
                                  "G (Default " +
                                  (
                                    parseInt(
                                      RAMCheck.filter(
                                        (i) =>
                                          listLastLogs.filter(
                                            (i) => i.log_line === u.id_line
                                          )[0] !== undefined &&
                                          listLastLogs
                                            .filter(
                                              (i) => i.log_line === u.id_line
                                            )[0]
                                            .PID.search(i.split(":")[0]) !== -1
                                      )[0].split(":")[1]
                                    ) /
                                    1024 /
                                    1024
                                  )
                                    .toFixed(2)
                                    .toString() +
                                  "G)\n=============================================================\n" +
                                  getPIDItem(
                                    listLastLogs.filter(
                                      (i) => i.log_line === u.id_line
                                    )[0].log_content
                                  )
                              : getPIDItem(
                                  listLastLogs.filter(
                                    (i) => i.log_line === u.id_line
                                  )[0].log_content
                                )
                          );
                          setOpenArea(false);
                          setOpenHistory(false);
                          setOpenModal(true);
                        }}
                      >
                        {RAMCheck.length > 0 &&
                        RAMCheck.filter(
                          (i) =>
                            listLastLogs.filter(
                              (i) => i.log_line === u.id_line
                            )[0] !== undefined &&
                            listLastLogs
                              .filter((i) => i.log_line === u.id_line)[0]
                              .PID.search(i.split(":")[0]) !== -1
                        )[0] !== undefined &&
                        (parseInt(
                          RAMCheck.filter(
                            (i) =>
                              listLastLogs.filter(
                                (i) => i.log_line === u.id_line
                              )[0] !== undefined &&
                              listLastLogs
                                .filter((i) => i.log_line === u.id_line)[0]
                                .PID.search(i.split(":")[0]) !== -1
                          )[0].split(":")[1]
                        ) *
                          105) /
                          100 <
                          getRAMDevice(
                            listLastLogs.filter(
                              (i) => i.log_line === u.id_line
                            )[0] !== undefined &&
                              listLastLogs.filter(
                                (i) => i.log_line === u.id_line
                              )[0].log_content !== ""
                              ? listLastLogs.filter(
                                  (i) => i.log_line === u.id_line
                                )[0].log_content
                              : ""
                          )
                          ? "extra RAM - "
                          : ""}
                        {/* {listLastLogs.filter(
                          (i) => i.log_line === u.id_line
                          )[0] !== undefined &&
                          listLastLogs.filter((i) => i.log_line === u.id_line)[0]
                          .log_content !== "" ? (
                          getPIDItem(
                            listLastLogs.filter(
                              (i) => i.log_line === u.id_line
                            )[0].log_content
                          ).split("\n").length > 8 ? (
                            specialMoel.filter(
                              (i) =>
                                getPIDItem(
                                  listLastLogs.filter(
                                    (i) => i.log_line === u.id_line
                                  )[0].log_content
                                )
                                  .split("\n")
                                  .slice(3)
                                  .toString()
                                  .search(i) !== -1
                            ).length > 2 ? (
                              <marquee>
                                {specialMoel
                                  .filter(
                                    (i) =>
                                      getPIDItem(
                                        listLastLogs.filter(
                                          (i) => i.log_line === u.id_line
                                        )[0].log_content
                                      )
                                        .split("\n")
                                        .slice(3)
                                        .toString()
                                        .search(i) !== -1
                                  )
                                  .toString()
                                  .replace(/,/g, " , ")}{" "}
                                inside
                              </marquee>
                            ) : (
                              specialMoel
                                .filter(
                                  (i) =>
                                    getPIDItem(
                                      listLastLogs.filter(
                                        (i) => i.log_line === u.id_line
                                      )[0].log_content
                                    )
                                      .split("\n")
                                      .slice(3)
                                      .toString()
                                      .search(i) !== -1
                                )
                                .toString()
                                .replace(/,/g, " , ") + " inside"
                            )
                          ) : (
                            ""
                          )
                         ) : (
                          ""
                          )} 
                        */}
                        
                      {
                        
                        listLastLogs.filter(
                          (i) => i.log_line === u.id_line
                        )[0]?.log_content.search("Extra items:")!==-1&&
                        listLastLogs.filter(
                          (i) => i.log_line === u.id_line
                        )[0]?.log_content.split("\n").filter((i)=>i.search("Extra items:")!==-1)[0]?.split(":")[1]!==undefined?
                        listLastLogs.filter(
                          (i) => i.log_line === u.id_line
                        )[0]?.log_content.split("\n").filter((i)=>i.search("Extra items:")!==-1)[0]?.split(":")[1]?.length > 14?
                        <marquee>{listLastLogs.filter(
                          (i) => i.log_line === u.id_line
                        )[0]?.log_content.split("\n").filter((i)=>i.search("Extra items:")!==-1)[0]?.split(":")[1]+" inside"}</marquee>:
                        listLastLogs.filter(
                          (i) => i.log_line === u.id_line
                        )[0]?.log_content.split("\n").filter((i)=>i.search("Extra items:")!==-1)[0]?.split(":")[1]+" inside":""
                      }

                      </div>
                      <div
                        style={{
                          position: "absolute",
                          left: "98%",
                          fontSize: "0.8rem",
                          width: "2%",
                          height: "100%",
                          fontWeight: 700,
                          alignItems: "center",
                          justifyContent: "center",
                          height: "100%",
                          display:
                            user.full_name === "US1 01-08" ? "none" : "flex",
                        }}
                      >
                        <Menu shadow="md" width={130} position="left-start">
                          <Menu.Target>
                            <IconButton style={{ width: 35, height: 35 }}>
                              <MoreVertIcon />
                            </IconButton>
                          </Menu.Target>

                          <Menu.Dropdown>
                            <Menu.Label>Option</Menu.Label>
                            <Menu position="left-start" shadow="md" width={300}>
                              <Menu.Target>
                                <button className="btnOptionZulip">
                                  <SendIcon
                                    style={{
                                      width: 15,
                                      height: 15,
                                      marginRight: 5,
                                    }}
                                  ></SendIcon>{" "}
                                  Send report
                                </button>
                              </Menu.Target>
                              <Menu.Dropdown>
                                <Menu.Label>Stream - Topic</Menu.Label>
                                {listStream?.map((t) => (
                                  <Menu.Item
                                    onClick={async () => {
                                      socket?.emit(
                                        "updateListTestingFromManualTest",
                                        u
                                      );
                                      toast.success(
                                        "Begin test and send report"
                                      );
                                      setTimeout(async () => {
                                        await axios.post(manualTest, {
                                          infoStation: infoStation,
                                          listLine: u,
                                          stream: t.split(":")[1],
                                          topic: t.split(":")[0],
                                          valueConfirmPhysical: "",
                                        });
                                      }, 15000);
                                    }}
                                  >
                                    <span>
                                      <b>{t.split(":")[1]}</b> -{" "}
                                      {t.split(":")[0]}
                                    </span>
                                  </Menu.Item>
                                ))}
                              </Menu.Dropdown>
                            </Menu>
                            <Menu.Item
                              onClick={() => {
                                setLogs("");
                                setTimeout(() => {
                                  autoScroll();
                                }, 5000);
                                socket?.emit(
                                  "updateListTestingFromManualTest",
                                  u
                                );
                                setOpenedTerminal(true);
                                socket?.emit("createTelnet", {
                                  port: infoStation.sta_port_telnet,
                                  host: infoStation.sta_ip,
                                  email: user.username,
                                  clear_line: u.line_clear,
                                  line_number: u.line_number,
                                  line_port: u.line_port,
                                });

                                setTimeout(() => {
                                  socket?.emit("receiverCommand", {
                                    email: user.username,
                                    command: "\r",
                                  });
                                }, 3000);
                              }}
                            >
                              <div style={{ display: "flex" }}>
                                <TerminalIcon
                                  style={{
                                    width: 18,
                                    height: 18,
                                    marginRight: 5,
                                  }}
                                />{" "}
                                Terminal
                              </div>
                            </Menu.Item>
                          </Menu.Dropdown>
                        </Menu>
                      </div>
                      <div
                        style={{
                          position: "absolute",
                          left: "98%",
                          fontSize: "0.8rem",
                          width: "2%",
                          height: "100%",
                          fontWeight: 700,
                          alignItems: "center",
                          justifyContent: "center",
                          height: "100%",
                          display:
                            user.full_name === "US1 01-08" ? "flex" : "none",
                        }}
                      >
                        <Tooltip title="Send report" placement="left">
                          <IconButton>
                            <SendAndArchiveSharpIcon
                              color="action"
                              onClick={() => {
                                setOpenComfirm(true);
                                setValueConfirmPhysical({
                                  ...valueConfirmPhysical,
                                  infoLine: u,
                                });
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                    {/* </Tooltip> */}
                  </div>
                ))
              : ""}
          </div>
        </div>
      </div>
      {/* <div style={{width:"100%",height:"50%", overflow:"hidden", display: nameStationSelect.search("US1") === -1 &&"none"}}>
      
        <iframe
          src={process.env.REACT_APP_SOCKET_SERVER_ADDRESS}
          // src="http://localhost:3000/"
          title="description"
          width={"100%"}
          height={"190%"}
          style={{
            display:"block",
            marginTop:"-7.5vh"
          }}
        ></iframe>
        
      </div > */}
      {/* {line !== undefined ? ( */}
      <Modal
        opened={openComfirm}
        onClose={() => {
          setOpenComfirm(false);
          setValueConfirmPhysical({ option: "", value: "", infoLine: {} });
        }}
        size={"45%"}
        title={<h2>Confirm physical visual check</h2>}
      >
        <div style={{ padding: "1%" }}>
          <FormControl style={{ width: "100%" }}>
            <FormLabel id="demo-radio-buttons-group-label">Option</FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={valueConfirmPhysical.option}
              name="radio-buttons-group"
              onChange={(e) => {
                // if(e.target.value!=="Issue"){
                //   setValueConfirmPhysical({
                //     option: valueConfirmPhysical.option,
                //     infoLine: valueConfirmPhysical.infoLine,
                //     value: ""
                //   });
                // setIsLoading()
                // }
                setValueConfirmPhysical({
                  ...valueConfirmPhysical,
                  option: e.target.value,
                });
              }}
            >
              <FormControlLabel
                value="Physical tested good"
                checked={
                  valueConfirmPhysical.option === "Physical tested good"
                    ? true
                    : false
                }
                control={<Radio />}
                label="Confirm physical visual check good"
              />
              <FormControlLabel
                value="Issue"
                checked={valueConfirmPhysical.option === "Issue" ? true : false}
                control={<Radio />}
                label="Issue: "
                onClick={()=>{
                  setTimeout(() => {
                    textareaRef.current.focus();
                  }, 100);
                }}
              />
            </RadioGroup>
            <div style={{ position: "relative" }}>
              <TextareaAutosize
                style={{ width: "100%", height: "15vh", fontSize: "1rem" }}
                value={valueConfirmPhysical.value}
                disabled={
                  valueConfirmPhysical.option !== "Issue" ? true : false
                }
                onChange={(e) => {
                  setValueConfirmPhysical({
                    ...valueConfirmPhysical,
                    value: e.target.value,
                  });
                }}
                ref={textareaRef}
              ></TextareaAutosize>
              <div
                style={{
                  width: "100%",
                  height: "15vh",
                  position: "absolute",
                  top: 0,
                }}
                onClick={() => {
                  setTimeout(() => {
                    textareaRef.current.focus();
                  }, 100);
                  setValueConfirmPhysical({
                    ...valueConfirmPhysical,
                    option: "Issue",
                  });
                }}
              ></div>
            </div>

            <div
              style={{ margin: "1% 0", display: "flex", flexFlow: "column" }}
            >
              <span
                style={{
                  fontSize: "1.3rem",
                  fontStyle: "italic",
                  color: "red",
                }}
              >
                * The report can only be sent after the 'Confirm' button is
                clicked.
              </span>
              <Button
                style={{ width: "25%", margin: "3% 0 0 37.5%" }}
                variant="contained"
                onClick={async () => {
                  if (valueConfirmPhysical.option === "") {
                    toast.warning("Please select option");
                  } else {
                    if (
                      valueConfirmPhysical.option === "Issue" &&
                      valueConfirmPhysical.value === ""
                    ) {
                      toast.warning("What is the issue?");
                    } else {
                      socket?.emit(
                        "updateListTestingFromManualTest",
                        valueConfirmPhysical?.infoLine
                      );
                      toast.success("Begin test and send report");
                      
                      let info = valueConfirmPhysical
                      setValueConfirmPhysical({
                        option: "",
                        value: "",
                        infoLine: {},
                      });
                      setTimeout(async () => {
                        await axios.post(manualTest, {
                          infoStation: infoStation,
                          listLine: info?.infoLine,
                          stream: "US Auto-test",
                          topic: "Notifications",
                          valueConfirmPhysical: info,
                        });
                      }, 15000);
                      
                      setOpenComfirm(false);
                    }
                  }
                }}
              >
                Confirm
              </Button>
            </div>
          </FormControl>
        </div>
      </Modal>
      <Modal
        opened={openModal}
        onClose={() => {
          setOpenModal(false);
          setText("");
        }}
        size={"80%"}
        title={
          openHistory ? (
            <h3>History</h3>
          ) : line !== undefined ? (
            <h3>Line {line.line_number}</h3>
          ) : (
            <h3>Report box</h3>
          )
        }
      >
        <div
          style={{
            width: "100%",
            height: "80vh",
            // marginTop: enableUpdateIOS ? "-8.5vh" : "-4vh",
            display: openHistory ? "block" : "none",
            // display: "block",
            marginLeft: "10px",
          }}
        >
          {rows !== undefined && (
            <ListHistory
              columns={columns}
              rows={rows}
              setOpenHistory={setOpenHistory}
              setOpenArea={setOpenArea}
            />
          )}
        </div>

        <div
          style={{
            width: "100%",
            display: openHistory === false ? "block" : "none",
            // margin: enableUpdateIOS ? "0vh 1% 0 1%" : "0vh 1% 0 1%",
            position: "relative",
          }}
        >
          {openHistory === false && (
            <div id="reportDiv" style={{ fontSize: "5px", width: "100%" }}>
              <div>
                <Highlight
                  id="showReport"
                  highlight={highlightList}
                  highlightColor="#d1ff1b"
                  style={{
                    maxWidth: "100%",
                    display: openArea ? "none" : "block",
                    whiteSpace: "pre",
                    fontSize: "0.7rem",
                    height: "75vh",
                    border: "solid 0.5px gray",
                    overflow: "auto",
                    overflowX: "auto",
                    wordWrap: "break-word",
                  }}
                >
                  {text}
                </Highlight>
              </div>

              <textarea
                id="report"
                style={{
                  display: openArea ? "block" : "none",
                  width: print ? "170%" : "100%",
                  height: print ? "110vh" : "75vh",
                }}
                value={text}
                onChange={(e) => {
                  setText(e.target.value);
                }}
              ></textarea>
            </div>
          )}
          <div
            style={{
              display: "block",
              position: "absolute",
              bottom: "2%",
              right: 0,
              width: "8%",
            }}
          >
            <div
              style={{
                width: "100%",
                textAlign: "right",
                display: "flex",
                flexFlow: "column",
              }}
            >
              <Button
                onClick={() => {
                  setOpenArea(true);
                }}
                sx={{
                  width: "100%",
                  fontSize: "0.6rem",
                  boxShadow: "0 0 5px 0 gray",
                  fontWeight: 700,
                  boxShadow: "0 0 5px 0 gray",
                }}
                size="small"
              >
                Edit
              </Button>
              <Button
                sx={{
                  width: "100%",
                  fontSize: "0.6rem",
                  boxShadow: "0 0 5px 0 gray",
                  fontWeight: 700,
                }}
                size="small"
                onClick={() => {
                  setText(selectText);
                }}
              >
                Full
              </Button>
              <Button
                sx={{
                  width: "100%",
                  fontSize: "0.6rem",
                  boxShadow: "0 0 5px 0 gray",
                  fontWeight: 700,
                }}
                size="small"
                onClick={() => {
                  let textTemp = text.replace(/\$  /g, "");

                  //Get inventory information in report
                  let inventory = "";
                  let invOver = textTemp
                    .slice(
                      textTemp.search("NAME: "),
                      textTemp.search("NAME: ") + 3000
                    )
                    .split("\n");

                  invOver.map((u) => {
                    if (u.search("NAME: ") !== -1 || u.search("PID: ") !== -1) {
                      inventory = inventory + u + "\n";
                    }
                  });

                  //Get system image file information in report
                  let imageFile = textTemp
                    .substr(textTemp.search("System image file"), 500)
                    .split("\n")[0];

                  //Get device configuration in report
                  let configKey = "";
                  let configOver = "";
                  if (textTemp.search("bytes of memory") !== -1) {
                    configOver = textTemp
                      .slice(
                        textTemp.search("bytes of memory") - 200,
                        textTemp.search("bytes of memory") + 100
                      )
                      .split("\n");
                    configOver.map((u) => {
                      if (u.search("bytes of memory") !== -1) {
                        configKey = configKey + u;
                      }
                    });
                  } else {
                    if (textTemp.search("bytes of physical memory") !== -1) {
                      configOver = textTemp
                        .slice(
                          textTemp.search(" bytes of physical memory") - 200,
                          textTemp.search(" bytes of physical memory") + 100
                        )
                        .split("\n");
                      configOver.map((u) => {
                        if (u.search(" bytes of physical memory") !== -1) {
                          configKey = configKey + u;
                        }
                      });
                    }
                  }

                  let config = textTemp.slice(
                    textTemp.indexOf(configKey),
                    textTemp.search("Configuration register is") +
                      textTemp
                        .slice(
                          textTemp.search("Configuration register is"),
                          textTemp.search("Configuration register is") + 100
                        )
                        .split("\n")[0].length
                  );

                  setText(
                    (inventory + "\n\n" + imageFile).replace(/\n\n/g, "\n") +
                      "\n\n" +
                      config.replace(/\n\n/g, "\n")
                  );
                }}
              >
                short
              </Button>

              <Button
                sx={{
                  width: "100%",
                  fontSize: "0.6rem",
                  boxShadow: "0 0 5px 0 gray",
                  fontWeight: 700,
                }}
                size="small"
                onClick={() => {
                  let textTemp = text.replace(/\$  /g, "");
                  //Get inventory information in report
                  let showInv =
                    textTemp
                      .slice(
                        textTemp.search("NAME: "),
                        textTemp.search("NAME: ") + 1000
                      )
                      .split("\n")[0] +
                    "\n" +
                    textTemp
                      .slice(
                        textTemp.search("NAME: "),
                        textTemp.search("NAME: ") + 1000
                      )
                      .split("\n")[1];

                  //Get memory in report
                  let bytesOfMem = "";
                  let bytesOfMemOver = textTemp.slice(
                    textTemp.search("bytes of memory.") - 100,
                    textTemp.search("bytes of memory.") +
                      "bytes of memory.".length
                  );
                  if (bytesOfMemOver.search("bytes of memory.") !== -1) {
                    bytesOfMem =
                      bytesOfMemOver.split("\n")[
                        bytesOfMemOver.split("\n").length - 1
                      ];
                    let infoMem = bytesOfMem
                      .slice(
                        bytesOfMem.search("bytes of memory") - 100,
                        bytesOfMem.search("bytes of memory")
                      )
                      .split(" ")
                      .reverse()[1];

                    //Convert Kb to Gb
                    if (infoMem) {
                      if (infoMem.split("/").length > 1) {
                        let num = parseInt(infoMem.split("/")[0].slice(0, -1));
                        num = num / 1024 / 1024;
                        bytesOfMem = bytesOfMem.replace(
                          infoMem.split("/")[0],
                          num.toFixed(2).toString() + "G"
                        );
                        bytesOfMem = bytesOfMem.replace(
                          "/" + infoMem.split("/")[1],
                          ""
                        );
                      } else {
                        let num = parseInt(infoMem.split("/")[0].slice(0, -1));
                        num = num / 1024 / 1024;
                        bytesOfMem = bytesOfMem.replace(
                          infoMem.split("/")[0],
                          num.toFixed(2).toString() + "G"
                        );
                      }
                    }
                  }

                  let ios = "";
                  if (textTemp.search("Cisco IOS XE Software") !== -1) {
                    ios =
                      ios +
                      textTemp
                        .slice(
                          textTemp.search("Cisco IOS XE Software"),
                          textTemp.search("Cisco IOS XE Software") + 1000
                        )
                        .split("\n")[0];
                  } else {
                    if (textTemp.search("Cisco IOS Software") !== -1) {
                      ios =
                        ios +
                        textTemp
                          .slice(
                            textTemp.search("Cisco IOS Software"),
                            textTemp.search("Cisco IOS Software") + 1000
                          )
                          .split("\n")[0];
                    }
                  }
                  let licensePacket =
                    textTemp.search(
                      "Technology Package License Information"
                    ) !== -1
                      ? (
                          textTemp
                            .slice(
                              textTemp.search(
                                "Technology Package License Information"
                              ),
                              textTemp.search(
                                "Technology Package License Information"
                              ) +
                                "Technology Package License Information"
                                  .length +
                                100
                            )
                            .split("\n")[0] +
                          "\n" +
                          textTemp.slice(
                            textTemp.search(
                              "Technology Package License Information"
                            ) +
                              textTemp
                                .slice(
                                  textTemp.search(
                                    "Technology Package License Information"
                                  ),
                                  textTemp.search(
                                    "Technology Package License Information"
                                  ) +
                                    "Technology Package License Information"
                                      .length +
                                    100
                                )
                                .split("\n")[0].length +
                              3,
                            textTemp.search(
                              "Technology Package License Information"
                            ) +
                              "Technology Package License Information".length +
                              1000
                          )
                        ).split("\n\n")[0]
                      : "";
                  let licenseIndexOver = textTemp
                    .slice(
                      textTemp.search("Index 1"),
                      textTemp.search("Index 1") + 10000
                    )
                    .split("\n\n")[0];
                  let licenseIndex = "";

                  for (
                    let i = 1;
                    i < licenseIndexOver.split("Index").length;
                    i++
                  ) {
                    let temp = licenseIndexOver.slice(
                      licenseIndexOver.search("Index " + i),
                      licenseIndexOver.search("Index " + (i + 1))
                    );
                    if (temp.search("Permanent") !== -1) {
                      for (let j = 0; j < temp.split("\n").length; j++) {
                        if (
                          temp.split("\n")[j].search("Feature") !== -1 ||
                          temp.split("\n")[j].search("Permanent") !== -1
                        )
                          licenseIndex =
                            licenseIndex + "\n" + temp.split("\n")[j];
                      }
                    }
                  }

                  if (textTemp.search("physical memory") !== -1) {
                    let memPhyOver = textTemp.slice(
                      textTemp.search("physical memory") - 100,
                      textTemp.search("physical memory") +
                        "physical memory".length
                    );
                    let memPhysical =
                      memPhyOver.split("\n")[memPhyOver.split("\n").length - 1];

                    let physiMem = memPhysical
                      .slice(
                        memPhysical.search("bytes of physical memory") - 50,
                        memPhysical.search("bytes of physical memory")
                      )
                      .split(" ")
                      .reverse()[1];

                    for (let i = 0; i < physiMem.split("/").length; i++) {
                      let num = parseInt(physiMem.split("/")[i].slice(0, -1));
                      num = num / 1024 / 1024;
                      memPhysical = memPhysical.replace(
                        physiMem.split("/")[i],
                        num.toFixed(2).toString() + "G"
                      );
                    }

                    setText(
                      showInv +
                        "\n\n" +
                        bytesOfMem +
                        "\n\n" +
                        memPhysical +
                        "\n\n" +
                        ios +
                        "\n\n" +
                        licensePacket +
                        "\n" +
                        licenseIndex
                    );
                  } else {
                    setText(
                      showInv +
                        "\n\n" +
                        bytesOfMem +
                        "\n\n" +
                        ios +
                        "\n\n" +
                        licensePacket +
                        "\n\n" +
                        licenseIndex
                    );
                  }
                }}
              >
                license
              </Button>

              <Button
                sx={{
                  width: "100%",
                  fontSize: "0.6rem",
                  boxShadow: "0 0 5px 0 gray",
                  fontWeight: 700,
                }}
                size="small"
                onClick={() => {
                  let arr = ["abc$$", "def$$", "$$"];
                  let listPID_SN = "";
                  let arrayInfo = [];
                  let arrayPID = [];
                  let arraySN = [];
                  let textTemp = text.replace(/\$  /g, "");
                  let arrayLine = textTemp.split("\n");
                  for (let i = 0; i < arrayLine.length; i++) {
                    if (
                      (arrayLine[i].search("NAME:") !== -1 ||
                        arrayLine[i].search("Name:") !== -1) &&
                      arrayLine[i].search("DESCR:") !== -1 &&
                      arrayLine[i + 1].search("PID:") !== -1 &&
                      arrayLine[i + 1].search("SN:") !== -1
                    ) {
                      if (
                        arrayLine[i + 1]
                          .slice(
                            arrayLine[i + 1].search("PID:"),
                            arrayLine[i + 1].search("PID:") + 100
                          )
                          .split(",")[0]
                          .split(":")[1]
                          .trim() !== "" &&
                        arrayLine[i + 1]
                          .slice(
                            arrayLine[i + 1].search("SN:"),
                            arrayLine[i + 1].search("SN:") + 100
                          )
                          .split(",")[0]
                          .split(":")[1]
                          .trim() !== ""
                      ) {
                        arrayInfo.push(arrayLine[i] + "\n" + arrayLine[i + 1]);
                        arrayPID.push(
                          arrayLine[i + 1]
                            .slice(
                              arrayLine[i + 1].search("PID:"),
                              arrayLine[i + 1].search("PID:") + 100
                            )
                            .split(",")[0]
                            .split(":")[1]
                            .trim()
                        );
                        arraySN.push(
                          arrayLine[i + 1]
                            .slice(
                              arrayLine[i + 1].search("SN:"),
                              arrayLine[i + 1].search("SN:") + 100
                            )
                            .split(",")[0]
                            .split(":")[1]
                            .trim()
                        );
                      }
                    }
                  }

                  for (let x = 0; x < arrayPID.length; x++) {
                    for (let y = 0; y < arrayPID.length; y++) {
                      if (
                        arrayPID[x] === arrayPID[y] &&
                        arraySN[x] === arraySN[y] &&
                        x !== y
                      ) {
                        arrayInfo.splice(arrayPID.indexOf(arrayPID[y]), 1);
                        arraySN.splice(arrayPID.indexOf(arrayPID[y]), 1);
                        arrayPID.splice(arrayPID.indexOf(arrayPID[y]), 1);
                      }
                    }
                  }

                  for (let x = 0; x < arrayInfo.length; x++) {
                    for (let y = 0; y < arrayInfo.length; y++) {
                      if (
                        arrayInfo[x]
                          .slice(
                            arrayInfo[x].search("DESCR:"),
                            arrayInfo[x].search("DESCR:") + 100
                          )
                          .split("\n")[0]
                          .split(":")[1]
                          .trim() ===
                          arrayInfo[y]
                            .slice(
                              arrayInfo[y].search("DESCR:"),
                              arrayInfo[y].search("DESCR:") + 100
                            )
                            .split("\n")[0]
                            .split(":")[1]
                            .trim() &&
                        x !== y
                      ) {
                        arrayInfo[x] = arrayInfo[x].replace(
                          arrayInfo[x]
                            .slice(
                              arrayInfo[x].search("SN:"),
                              arrayInfo[x].search("SN:") + 1000
                            )
                            .split("\n")[0]
                            .trim(),
                          arrayInfo[x]
                            .slice(
                              arrayInfo[x].search("SN:"),
                              arrayInfo[x].search("SN:") + 1000
                            )
                            .split("\n")[0]
                            .trim() +
                            ", " +
                            arrayInfo[y]
                              .slice(
                                arrayInfo[y].search("SN:"),
                                arrayInfo[y].search("SN:") + 1000
                              )
                              .split("\n")[0]
                              .split(":")[1]
                              .trim()
                        );

                        arrayInfo.splice(y, 1);
                      }
                    }
                  }

                  arrayInfo.map((u) => {
                    u = u.replace(
                      u
                        .slice(u.search("PID:"), u.search("PID:") + 2000)
                        .split("\n")[0],
                      u
                        .slice(u.search("PID:"), u.search("PID:") + 100)
                        .split(",")[0]
                        .split(":")[1]
                        .trim() +
                        "\n" +
                        u
                          .slice(u.search("SN:"), u.search("SN:") + 1000)
                          .split("\n")[0]
                          .split(":")[1]
                          .trim() +
                        "\n\n"
                    );
                    listPID_SN = listPID_SN + u + "\n";
                  });
                  // console.log(listPID_SN);
                  setText(listPID_SN);
                }}
              >
                PID-SN
              </Button>

              <Button
                sx={{
                  width: "100%",
                  fontSize: "0.6rem",
                  boxShadow: "0 0 5px 0 gray",
                  fontWeight: 700,
                }}
                size="small"
                onClick={() => {
                  navigator.clipboard.writeText(text);
                }}
              >
                Copy
              </Button>
            </div>
            <div
              style={{
                width: "100%",
                textAlign: "right",
                display: "flex",
                flexFlow: "column",
              }}
            >
              <Button
                sx={{
                  width: "100%",
                  fontSize: "0.6rem",
                  boxShadow: "0 0 5px 0 gray",
                  fontWeight: 700,
                }}
                size="small"
                onClick={() => {
                  setPrint(true);
                  setOpenArea(true);
                  setTimeout(() => {
                    printJS({
                      printable: "report",
                      type: "html",
                      documentTitle: "IPSUPPLY",
                      style: "#report {border:none}",
                    });
                    setPrint(false);
                  }, 5);
                }}
              >
                Print
              </Button>
              <Button
                sx={{
                  fontSize: "0.6rem",
                  boxShadow: "0 0 5px 0 gray",
                  fontWeight: 700,
                  width: "100%",
                }}
                size="small"
                onClick={() => {
                  download();
                }}
              >
                Download
              </Button>
              <Button
                sx={{
                  width: "100%",
                  fontSize: "0.6rem",
                  boxShadow: "0 0 5px 0 gray",
                  fontWeight: 700,
                }}
                size="small"
                onClick={() => {
                  setOpenHistory(true);
                }}
              >
                History
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      {/* ) : (
        ""
      )} */}
      <Modal
        opened={openedTerminal}
        // onKeyDown={(e)=>{if (e.key === 'Escape') {
        //   setOpenedTerminal(true);
        // }}}
        onClose={() => {
          setOpenedTerminal(false);
          socket?.emit("closeTelnet", { email: user.username });
          setLogs("");
        }}
        size={"80%"}
        title={"Command line interface"}
      >
        <div
          style={{
            height: "70vh",
            width: "100%",
            backgroundColor: "#333",
            display: "flex",
            flexFlow: "column-reverse",
          }}
        >
          {/* <input
            placeholder="Enter command"
            onKeyDown={(e) => {
              
              if (e.key === "Enter") {
                socket?.emit("receiverCommand", {
                  email: user.username,
                  command: e.target.value,
                });
                e.target.value = "";
              }
            }}
            style={{ width: "100%", height: "5%" }}
          ></input> */}
          <textarea
            id="textarea_id"
            value={logs}
            style={{
              height: "100%",
              width: "100%",
              backgroundColor: "#333",
              color: "#c7ffb5",
            }}
            onChange={(e) => {
              setCount(count + 1);
              setLogs(e.target.value);
              // localStorage.setItem("log", e.target.value)
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                socket?.emit("receiverCommand", {
                  email: user.username,
                  command: e.target.value
                    .slice(e.target.value.length - count, e.target.value.length)
                    .trim()
                    .replace(/#/g, ""),
                });
              }

              if (
                e.key === " " &&
                e.target.value
                  .slice(e.target.value.length - 20, e.target.value.length)
                  .search("More") !== -1
              ) {
                socket?.emit("receiverCommand", {
                  email: user.username,
                  command: " \r",
                });
              }

              if (e.key === "Backspace") {
                setCount(count - 2);
              }
            }}
          >
            {logs}
          </textarea>
        </div>
      </Modal>
    </div>
  );
};

export default TestDevice;
